<script setup lang="ts">
const { value: haveMedicare } = useField('haveMedicare')
</script>

<template>
  <div class="text-2xl sm:text-3xl text-red-500 text-center">
    Tómate este cuestionario
  </div>

  <div class="text-3xl sm:text-4xl text-center">
    ¿Estás inscrito en Medicare?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicare = true"
    >
      Sí
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicare = false"
    >
      No
    </Button>
  </div>
</template>
