<script setup lang="ts">
const start = Date.now()
const now = useNow()

const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)
</script>

<template>
  <div class="text-3xl sm:text-4xl text-center">
    Looks like you may qualify for $$$ back in premium reduction benefits!
  </div>

  <DoubleClickToCall v-if="exp?.additionaBuybackCallButton?.newLayout" />
  <ClickToCall v-else :props="{ ctcLocation: 'last-step' }" tty />

  <div class="text-lg sm:text-xl font-medium text-center">
    Act quickly: <span class="text-red"> {{ timer }} </span>
  </div>
</template>
