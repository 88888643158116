interface Query {
  [key: string]: string | null | undefined
}

export const getSession = () => {
  return window.getSession()
}

export const getSessionEnhanced = () => {
  const session = getSession()

  const key = `url_query_params_session_${session.id}`
  const query: Query =
    JSON.parse(String(window.localStorage.getItem(key))) || {}

  return {
    ...session,
    query,
    isPaidUser:
      (!!query.etid && query.etid.length >= 15 && !!query.utm_campaign) ||
      (!!query.token && query.token.length >= 15 && !!query.utm_campaign) ||
      (!!query.gclid && query.gclid.length >= 15 && !!query.utm_campaign) ||
      (!!query.ScCid && query.ScCid.length >= 15 && !!query.utm_campaign) ||
      (!!query.fbclid && query.fbclid.length >= 15 && !!query.utm_campaign) ||
      (!!query.qstid && query.qstid.length >= 15 && !!query.utm_campaign) ||
      (!!query.ttclid && query.ttclid.length >= 15 && !!query.utm_campaign) ||
      (!!query.saclid && query.saclid.length >= 15 && !!query.utm_campaign) ||
      (!!query.tbclid && query.tbclid.length >= 15 && !!query.utm_campaign) ||
      (!!query.wbraid && query.wbraid.length >= 15 && !!query.utm_campaign) ||
      (!!query.gbraid && query.gbraid.length >= 15),
  }
}

export const extendSession = () => {
  const session = getSession()
  session.dateLastActivity = Date.now()
  window.localStorage.setItem('session', JSON.stringify(session))
}
