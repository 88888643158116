<script setup lang="ts">
import type { Ref } from 'vue'

const lowBid = inject('lowBid') as Ref<boolean>
</script>

<template>
  <template v-if="lowBid">
    <FormProgress class="!mb-6" />

    <div class="text-2xl sm:text-3xl text-red text-center">
      Congratulations!
    </div>

    <div class="text-2xl sm:text-3xl text-center">
      We found {{ ip.lookup?.stateCode || '' }} plans with this flexible
      spending benefit. Call to continue with a licensed agent.
    </div>

    <MediaAlphaAd
      :placementId="
        query.utm_source?.toLowerCase() === 'ma'
          ? 'oLXPHoGj8cfc6Rk20iBxNYto4sr1mw'
          : 'xB51YFMkBb4D9ycHYv9YNkBUyjbbUA'
      "
      :user="{}"
    />
  </template>
  <template v-else-if="exp?.myOtcMedicareQuestion?.medicare">
    <template v-if="forms.groceryRange?.haveMedicare">
      <FormProgress class="!mb-6" />

      <div class="text-2xl sm:text-3xl text-red text-center">
        Congratulations!
      </div>

      <div class="text-2xl sm:text-3xl text-center">
        We found {{ ip.lookup?.stateCode || '' }} plans with this flexible
        spending benefit. Call to continue with a licensed agent.
      </div>

      <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />
    </template>
    <template v-else>
      <FormProgress class="!mb-6" />

      <div class="text-2xl sm:text-3xl text-red text-center">
        Congratulations!
      </div>

      <div class="text-2xl sm:text-3xl text-center">
        We found {{ ip.lookup?.stateCode || '' }} plans with this flexible
        spending benefit. Call to continue with a licensed agent.
      </div>

      <MediaAlphaAd
        :placementId="
          query.utm_source?.toLowerCase() === 'ma'
            ? 'oLXPHoGj8cfc6Rk20iBxNYto4sr1mw'
            : 'xB51YFMkBb4D9ycHYv9YNkBUyjbbUA'
        "
        :user="{}"
      />
    </template>
  </template>
  <template v-else>
    <FormProgress class="!mb-6" />

    <div class="text-2xl sm:text-3xl text-red text-center">
      Congratulations!
    </div>

    <div class="text-2xl sm:text-3xl text-center">
      We found {{ ip.lookup?.stateCode || '' }} plans with this flexible
      spending benefit. Call to continue with a licensed agent.
    </div>

    <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />
  </template>
</template>
