<script setup lang="ts">
import uncoverImage from '~/assets/uncover-image.png'
const router = useRouter()

const onClick = () => {
  router.push(`/go-4-benefits-bridge/start`)
}

onMounted(() => {
  const isPaidUser = getSessionEnhanced().isPaidUser

  if (!isPaidUser) {
    router.push('/go-4-benefits')
  }
})
</script>

<template>
  <Layout
    :hideHeader="true"
    class="bg-[#273238] flex flex-col min-h-[100vh]"
    footer="2024"
  >
    <div
      class="w-full h-[230px] md:hidden"
      :style="{
        backgroundImage: `url(${uncoverImage})`,
        backgroundSize: '130% 130%',
        backgroundPositionX: '30%',
      }"
    >
    </div>

    <div
      class="container h-full flex-grow min-h-[calc(100vh-230px)] flex mx-auto gap-6 py-6 text-xl sm:text-3xl font-bold flex flex-col text-white"
    >
      <div class="flex flex-col gap-4 flex-grow">
        <div class="flex flex-col md:flex-row md:pt-20 md:gap-3">
          <span class="text-4xl md:text-6xl">Uncover </span>
          <span class="text-4xl md:text-6xl">Medicare </span>
          <span class="text-4xl md:text-6xl">2024 benefits</span>
        </div>
        <span class="font-normal md:text-xl pt-12 md:pt-24"
          >{{ brand.name }}
        </span>

        <div class="flex flex-col gap-2 font-normal">
          <span>New to Medicare, Turning 65,</span>
          <span>Losing Coverage or Moving?</span>
        </div>
      </div>
      <div class="flex gap-4 mt-12 flex-grow-0 flex-col-reverse md:flex-row">
        <button
          @click="onClick"
          class="border border-white bg-[#273238] px-6 py-2 flex-grow text-white font-base"
        >
          Learn More
        </button>
        <ClickToCall v-slot="{ handleClick }">
          <a
            :href="phone.uri"
            @click="handleClick"
            :class="[
              'border border-red-500 bg-red-500 px-6 py-2 flex-grow text-[#273238] font-base text-center',
              'flex gap-4 justify-center md:gap-2 items-center',
            ]"
          >
            <Icon i-heroicons-solid:phone class="w-8 h-8 animate-shake" />
            <span>Call Now</span>
          </a>
        </ClickToCall>
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "med10", "m10", "boomer"],
    "robots": "noindex"
  }
}
</route>
