<script setup lang="ts">
const phoneTextColor = computed(() => 'text-red')
const phoneIconColor = computed(() => 'bg-red')
</script>

<template>
  <div class="bg-white shadow sticky top-0 z-20">
    <slot name="banner" />
    <div class="container flex items-center space-x-4 py-2">
      <RouterLink to="/" class="mr-auto">
        <LogoSmall class="sm:hidden" />
        <Logo class="hidden sm:block" />
      </RouterLink>
      <div class="hidden lg:block text-xl text-darkBlue">
        Need a quote? <span class="font-semibold"> Call toll free: </span>
      </div>
      <ClickToCall
        :props="{ ctcLocation: 'header' }"
        v-slot="{ handleClick }: any"
      >
        <a
          :href="phone.uri"
          @click="handleClick"
          :class="['block p-2 bg-opacity-20 rounded-full', phoneIconColor]"
        >
          <Icon i-heroicons-solid:phone :class="['w-8 h-8', phoneTextColor]" />
        </a>
      </ClickToCall>
      <div class="text-right text-sm text-gray-500">
        <div> Call a licensed insurance agent </div>
        <div :class="['text-2xl leading-7', phoneTextColor]">
          <ClickToCall
            :props="{ ctcLocation: 'header' }"
            v-slot="{ handleClick }: any"
          >
            <a :href="phone.uri" @click="handleClick">
              {{ phone.formatted }}
            </a>
          </ClickToCall>
        </div>
        <div>
          TTY Users:
          <span :class="['text-2xl leading-7', phoneTextColor]">
            711, 24/7
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
