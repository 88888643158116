import { ComputedRef } from 'vue'

export const callTransferCheck = async (
  isUnmounted: boolean,
  afid: ComputedRef<string | undefined>
): Promise<boolean> => {
  let lowBid = false
  if (placementId.value && !isUnmounted) {
    try {
      const res = await axios.post(
        import.meta.env.VITE_WEBSITE_API_URL +
          '/mediaalpha/call-transfers-check/v2',
        {
          data: {
            zip: ip.lookup?.postalCode,
            state: ip.lookup?.stateCode,
          },
          ip: ip.lookup?.value,
          local_hour: new Date().getHours(),
          max: 1,
          call_type: 'Inbound',
          transfer_type: 'cold',
          placement_id: placementId.value,
          ua: navigator.userAgent,
          url: location.href,
          version: 17,
          sub_1: getSessionEnhanced().query.afid || afid.value,
          sub_2: segment.anonymousId,
          sub_3: getSession().id,
          source: [
            getSessionEnhanced().query.afid || afid.value || 'null',
            query.utm_campaign || 'null',
            query.adgroupid || 'null',
            query.publisher_id || 'null',
            query.publisher || 'null',
            query.channel || 'null',
            xsmid.value || 'null',
          ],
        }
      )

      const data = res.data
      lowBid = data && data.ctc && data.ctc.ev < 10

      analytics.track('lowBidResult', {
        isLowBid: lowBid,
        maResponse: data,
      })
    } catch (e) {
      console.error(`There's some error loading transfer checks: `, { e })
    } finally {
      return lowBid
    }
  }
  return lowBid
}
