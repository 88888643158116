<script setup lang="ts">
useHead({
  title: `Additional benefits grocery 2024 - ${brand.name}`,
})

const isMounted = useMounted()

const router = useRouter()
const route = useRoute()

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if ((brand.id === 'bh' || brand.id === 'med10') && isPaidUser.value) {
    router.replace({
      path: '/additional-benefits-grocery-2024',
      query: route.query,
    })
  } else {
    if (isPaidUser.value) {
      enterExperiment('newHealthyFoodsImage')
    }
  }
})

const maxOtc = computed(() => {
  return states.find((state) => state.code === ip.lookup?.stateCode)?.maxOtc
})

const isMobile = ref(true)

onMounted(() => {
  isMobile.value = window.innerWidth < 640
})
</script>

<template>
  <Layout
    header="simple3"
    :hideHeader="brand.id === 'm10' && isPaidUser && isMobile"
    footer="grocery2024"
    class="container"
  >
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        <template v-if="isPaidUser">
          Have Medicare? Turning 65? Some Medicare members in
          {{ ip.lookup?.stateName || 'America' }} may qualify for
          <span class="text-red">
            {{ maxOtc ? `up to $${maxOtc}` : 'an allowance' }}
            to use towards groceries
          </span>
          with some Medicare Advantage Plans.
        </template>
        <template v-else>
          Are you shopping for Medicare Insurance plans? A licensed insurance
          agent can check your eligibility.
        </template>
      </div>

      <template v-if="isMounted">
        <template v-if="isPaidUser">
          <img
            v-if="exp?.newHealthyFoodsImage?.coupleHoldingCard"
            src="../../assets/grocery-2.jpg"
            width="550"
            height="359"
            class="w-[90%] sm:w-[400px] mx-auto"
          />
          <img
            v-else-if="exp?.newHealthyFoodsImage?.womanRedCard"
            src="../../assets/advertorial-hero.jpg"
            width="2550"
            height="1700"
            class="w-[90%] sm:w-[400px] mx-auto"
          />
          <img
            v-else-if="exp?.newHealthyFoodsImage?.fruitsWithText"
            src="../../assets/healthy-foods-fruits-with-text.jpg"
            width="400"
            height="209"
            class="w-[90%] sm:w-[400px] mx-auto"
          />
          <img
            v-else-if="exp?.newHealthyFoodsImage?.bananasWithText"
            src="../../assets/healthy-foods-bananas-with-text.jpg"
            width="400"
            height="209"
            class="w-[90%] sm:w-[400px] mx-auto"
          />
          <img
            v-else
            src="../../assets/grocery-2024.jpeg"
            class="w-[90%] sm:w-[400px] mx-auto"
            width="1100"
            height="576"
          />
        </template>
        <img
          v-else
          src="../../assets/helpline-seniors-hands-up.png"
          width="1254"
          height="836"
          class="w-[90%] sm:w-[400px] mx-auto"
        />
      </template>

      <UseForm v-slot="{ values }">
        <Form
          id="healthyFoodsGrocery2024"
          class="!max-w-3xl"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'medicare' },
            { id: 'medicareab', if: false },
            { id: 'sorry', if: false },
            { id: 'medicaid' },
            { id: 'us' },
            { id: 'loading' },
            { id: 'call', completed: true },
          ]"
          product="medicare"
        />
      </UseForm>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["otc"],
    "brands": ["bh", "med10", "m10"],
    "smid": {
      "m10": "MULTI-PLAN_EHWEB062302_M",
      "bh": "MULTI-PLAN_EHWEB062302_M",
      "med10": "MULTI-PLAN_EHWEB062302_M"
    },
    "xsmid": "MULTI-PLAN_EHWEB062302_M",
    "robots": "noindex"
  }
}
</route>
