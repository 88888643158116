<script setup lang="ts">
import { MediaAlphaSingleAdType } from '~/modules/ma'

const props = defineProps<{
  ad?: MediaAlphaSingleAdType
  shouldHideStar?: boolean
}>()

const contentImage = computed(() => {
  if (props.ad?.large_image_url) {
    return props.ad?.large_image_url
  } else if (props.ad?.medium_image_url) {
    return props.ad?.medium_image_url
  } else {
    return props.ad?.small_image_url
  }
})

const onClick = () => {
  if (props.ad) {
    analytics.track('planClicked', {
      ad: props.ad,
    })
  }
}

const descriptionVisible = ref(!isMobile)
</script>

<template>
  <div
    :class="[
      'bg-gray-100 w-full min-h-40 rounded-lg shadow-lg p-6 my-4 relative flex flex-col justify-center gap-4 relative',
      !isMobile && 'hover:bg-blue-300 transition-colors	duration-500',
    ]"
    v-if="ad"
  >
    <div class="star-container" v-if="!shouldHideStar">
      <Icon i-heroicons-solid:star class="text-white min-w-6 min-h-6" />
    </div>
    <span
      class="absolute text-xs text-gray-400 top-2 right-2 border border-gray-400 p-1 rounded"
    >
      Ad
    </span>
    <div class="flex flex-col md:items-center gap-8 justify-between px-4">
      <div
        :class="[
          'border-2 border-gray-200 p-4 flex justify-center mx-16 bg-white ',
          'md:min-w-40 md:max-w-40 md:mx-0 md:p-2',
        ]"
      >
        <img :src="contentImage" alt="Medicare Advantage Logo" class="w-full" />
      </div>
      <div class="flex flex-col">
        <h2
          :class="['text-2xl font-semibold text-center', 'md:text-left']"
          v-html="ad.headline"
        >
        </h2>
        <div class="flex flex-col items-center mt-4">
          <hr class="w-full h-[2px]" />
          <button
            v-if="isMobile"
            :class="[
              'rounded-full bg-gray-300 -mt-4 w-8 h-8 flex justify-center items-center cursor-pointer',
            ]"
            @click="descriptionVisible = !descriptionVisible"
          >
            <Icon
              i-heroicons-outline:chevron-up
              :class="[
                !descriptionVisible && 'rotate-180',
                'text-gray-500 transition-transform duration-150',
              ]"
            />
          </button>
          <span
            :class="['ad-description']"
            v-if="descriptionVisible"
            v-html="ad.description"
          >
          </span>
        </div>
      </div>

      <a
        :href="ad.click_url"
        @click="onClick"
        :class="[
          'block bg-blue-600 text-white text-center py-4 rounded-lg hover:bg-blue-700 transition-colors',
          'md:px-4 md:min-w-40',
        ]"
        target="_blank"
      >
        View My Quote
      </a>
    </div>
  </div>
</template>

<style scoped>
.star-container {
  @apply flex justify-between items-center mb-4 absolute top-0 left-0 bg-yellow-400 absolute w-16 h-16;

  clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
}

.star-container > span {
  transform: translate(26%, -46%);
}
</style>
