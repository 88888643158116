<script setup lang="ts">
useHead({
  title: `Medicare Premium Reduction Simple 2096 - ${brand.name}`,
})
</script>

<template>
  <Layout :hide-header="true" footer="giveback" class="container">
    <template #banner>
      <Banner v-if="isOutsideAEP">
        New to Medicare? On Medicaid? Moving?
      </Banner>
      <Banner v-else> Annual Enrollment Period is happening now </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-3 py-6">
      <div class="text-2xl sm:text-xl text-center font-semibold">
        2024 Medicare Advantage Plans With Part B Premium Reduction
      </div>
      <img
        class="w-64 md:w-80 mx-auto"
        src="../../assets/card-medicare-b.png"
        alt="Medicare Card with $174 green stamp"
      />
      <div
        class="text-3xl sm:text-4xl font-semibold text-red text-center font-bold"
      >
        $2096/yr
      </div>

      <div class="text-lg sm:text-xl text-center font-medium">
        ^ 2024 Medicare Part B Premium ^
      </div>

      <div class="text-xl sm:text-2xl text-center font-medium capitalize">
        See If You’re Eligible For a Plan with a Part B Premium Reduction
        <br />
        <strong>(Lower Monthly Premiums) </strong>
      </div>

      <div class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white">
        <UseForm v-slot="{ values }">
          <Form
            id="medicarePremiumReductionSimple2096"
            class="!max-w-3xl p-4"
            scrollBehavior="keepPos"
            :steps="[
              { id: 'medicareab' },
              { id: 'medicaid' },
              {
                id: 'call',
                if: values?.haveMedicare === true,
                completed: true,
              },
              {
                id: 'clicks',
                if: values?.haveMedicare === false,
              },
            ]"
            product="medicare"
          />
        </UseForm>
      </div>
      <div class="text-base sm:text-2xl text-center font-semibold">
        From plans like Humana, UnitedHealthcare®, Anthem, Blue Cross & Blue
        Shield, Aetna, Cigna Healthcare, Wellcare, Kaiser Permanente
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["giveback"],
    "brands": ["m10"],
    "smid": "MULTI-PLAN_EHWEB0124130_M",
    "xsmid": "MULTI-PLAN_EHWEB0124130_M",
    "robots": "noindex"
  }
}
</route>
