<script setup lang="ts">
import {
  ChatHeadline,
  Content,
  SelectedAnswersEmit,
  Message,
} from '~/modules/chat-ad'

import emilyNewAvatar from '~/assets/emily-b008.png'
import emilyWithGroceries from '~/assets/emily-w-groceries.png'
import { PlanRowView } from '~/modules/plan'

const isPaidUser = ref(true)

const matches = ref(false)

const plans = ref<PlanRowView[]>([])
const loading = ref<boolean>(true)
const error = ref<boolean>(false)
const top5Otc = ref<number>(0)
const selectedAnswers = ref<SelectedAnswersEmit | null>(null)
const showZipCodeInput = ref(true)
const zipCode = ref<string | null>('')

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)

  if (state) {
    return state
  }

  return {
    name: 'your area',
    medicaid: 'Medicaid',
    top5Otc: 0,
  }
})

onMounted(async () => {
  matches.value = window.matchMedia('(prefers-color-scheme: dark)').matches
  if (matches.value) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }

  isPaidUser.value = getSessionEnhanced().isPaidUser

  await until(() => ip.lookup).toBeTruthy({ timeout: 5000 })

  zipCode.value = ip.lookup?.postalCode ?? null

  if (zipCode.value) {
    showZipCodeInput.value = false
    try {
      await getPageData(zipCode.value)
    } catch (e) {
      error.value = true
    } finally {
      loading.value = false
    }
  } else {
    top5Otc.value = state.value?.top5Otc || 0
    showZipCodeInput.value = true
    loading.value = false
  }
})

const getPageData = async (zip: string) => {
  zipCode.value = zip
  try {
    plans.value = await getPlans({
      zipCode: zip,
      carrier_groups: [],
      amount: 1000,
      starting_from: 0,
    })
    await getTop5OtcData(zip, false)
  } catch (e) {
    error.value = true
  } finally {
    loading.value = false
    showZipCodeInput.value = false
  }
}

const getTop5OtcData = async (zip: string, is_dsnp: boolean) => {
  const response = await getTop5Otcs({
    zip_code: zip,
    is_dsnp: is_dsnp,
  })

  top5Otc.value = response.min_otc
    ? parseInt(`${response.min_otc}`, 10)
    : state.value?.top5Otc || 0
}

analytics.page({
  name: 'Chat Ad Page',
  category: 'LP',
  product: 'medicare',
})

const chatHeadline: ChatHeadline = {
  advisorName: 'Emily',
  image: emilyNewAvatar,
  title: 'Benefit Advisor',
  subtitle: 'Ask me about your Medicare Benefits!',
}

const onSelectAnswer = async (
  nextStepId: string,
  selected: SelectedAnswersEmit,
  callback: (response: any) => void
) => {
  selectedAnswers.value = {
    ...selectedAnswers.value,
    ...selected,
  }

  if (selected.haveMedicaid && selected.haveMedicaid === true) {
    const haveMedicaid = selected.haveMedicaid

    if (haveMedicaid === true && zipCode.value) {
      try {
        await getTop5OtcData(zipCode.value, haveMedicaid)
      } catch (e: any) {
        console.log('[BUG] error on retrieving new value for top5otc', {
          error: e.message,
        })
      }
    }
  }
  if (selected.zipcode) {
    zipCode.value = selected.zipcode
    await getPageData(selected.zipcode)
  }
  const messages = getMessageBlockById(nextStepId)

  return callback(messages)
}

const getMessageBlockById = (stepId: string): Message[] => {
  const messages = (content.value.messageBlocks?.[stepId] ?? []).filter(Boolean)
  return messages
}

const content = computed<Content>(() => {
  return {
    messageBlocks: {
      init: [
        {
          type: 'text',
          content: 'Hi there 👋!',
          from: 'emily',
          shouldShowAvatar: true,
        },
        {
          type: 'text',
          content: `I'm Emily. This will only take a minute.`,
          from: 'emily',
        },
        {
          type: 'buttons',
          content:
            '<span class="font-bold">Want to find out if you are eligible to receive a Grocery Allowance with some Medicare Advantage Plans? Tap Yes!</span>',
          from: 'emily',
          answers: [
            {
              text: 'Yes 👍',
              answer: 'quickQuestions',
              event: {
                property: 'findOutEligibleGroceryAllowance',
                value: true,
              },
            },
            {
              text: 'No',
              answer: 'otherBenefits',
              event: {
                property: 'findOutEligibleGroceryAllowance',
                value: false,
              },
            },
          ],
          clicked: false,
        },
      ],
      quickQuestions: [
        { type: 'text', content: `Yes`, from: 'user' },
        {
          type: 'text',
          content: `Great, let me ask you two quick questions`,
          from: 'emily',
        },
        {
          type: 'buttons',
          content:
            '<span class="font-bold">Are you new to Medicare, Turning 65, on Medicare and Losing coverage or Moving?</span>',
          from: 'emily',
          answers: [
            {
              text: 'Yes 👍',
              answer: showZipCodeInput.value === true ? 'zipcode' : 'location',
              event: {
                property: 'newMedicareOrTurning65OrLosingCoverage',
                value: true,
              },
            },
            {
              text: 'No',
              answer: 'medicareAB',
              event: {
                property: 'newMedicareOrTurning65OrLosingCoverage',
                value: false,
              },
            },
          ],
          clicked: false,
        },
      ],
      location: [
        { type: 'text', content: `Yes`, from: 'user' },
        {
          type: 'buttons',
          content: `<span class="font-bold">Do you live in ${state.value.name}?</span>`,
          from: 'emily',
          answers: [
            {
              text: 'Yes 👍',
              answer: 'enrollBranch',
              event: {
                property: 'livesInState',
                value: true,
              },
            },
            {
              text: 'No',
              answer: 'wrongLocation',
              event: {
                property: 'livesInState',
                value: false,
              },
            },
          ],
          clicked: false,
        },
      ],
      wrongLocation: [
        {
          type: 'text',
          content: `Sorry. My mistake.`,
          from: 'emily',
        },
        {
          type: 'redirect',
          answers: [
            {
              text: 'Submit',
              answer: 'zipcode',
              event: {
                eventProperty: 'hideUserAnwserForZip',
                eventValue: true,
              },
            },
          ],
        },
      ],
      zipcode: [
        selectedAnswers.value?.livesInState &&
        selectedAnswers.value?.livesInState === true
          ? {
              type: 'text',
              content: 'Yes',
              from: 'user',
            }
          : null,
        {
          type: 'text',
          content: '<span class="font-semibold">What is your zipcode?</span>',
          from: 'emily',
        },
        {
          type: 'zip',
          answers: [
            {
              text: 'Submit',
              answer: 'enrollBranch',
              event: { property: 'zipcode', value: null },
            },
          ],
        },
      ],
      enrollBranch: [
        selectedAnswers.value?.livesInState &&
        selectedAnswers.value?.livesInState === true
          ? {
              type: 'text',
              content: 'Yes',
              from: 'user',
            }
          : null,
        {
          type: 'text',
          content: `Good News! 🎉`,
          from: 'emily',
        },
        {
          type: 'text',
          content: `I just found a few plans near you.`,
          from: 'emily',
        },
        {
          type: 'text',
          content: `You could get ${USDollarFormatter(0).format(
            top5Otc.value
          )}/year or more in Grocery Allowance with these Medicare Advantage Plans!`,
          from: 'emily',
        },
        {
          type: 'redirect',
          from: 'emily',
          answers: [
            {
              text: 'Submit',
              answer: 'callBranch',
            },
          ],
        },
      ],
      callBranch: [
        selectedAnswers.value?.benefit
          ? {
              type: 'text',
              content: `Yes`,
              from: 'user',
            }
          : null,
        {
          type: 'callButton',
          from: 'emily',
          shouldShowReminder: true,
        },
      ],
      medicareAB: [
        {
          type: 'text',
          content: 'No',
          from: 'user',
        },
        {
          type: 'buttons',
          content: `<span class="font-bold">Do you have Medicare and Medicaid?</span>`,
          from: 'emily',
          answers: [
            {
              text: 'Yes 👍',
              answer: 'enrollBranch',
              event: { property: 'haveMedicareAndMedicaid', value: true },
            },
            {
              text: 'No',
              answer: 'enrollOnline',
              event: { property: 'haveMedicareAndMedicaid', value: false },
            },
          ],
          clicked: false,
        },
      ],

      reminder: [
        {
          type: 'image',
          image: emilyWithGroceries,
          from: 'emily',
        },

        {
          type: 'buttons',
          content: `<span class="font-bold">Are you still with me? Would love to help you find what you're looking for!?</span>`,
          from: 'emily',
          answers: [
            {
              text: 'Yes 👍',
              answer: 'goodTime',
              event: { property: 'haveMedicareAndMedicaid', value: true },
            },
          ],
          clicked: false,
        },
      ],
      goodTime: [
        {
          type: 'buttons',
          content: `<span class="font-bold">Is this a good time to talk?  You can also continue online.</span>`,
          from: 'emily',
          answers: [
            {
              text: 'Shop Online',
              answer: 'enrollOnline',
              event: { property: 'wantToShopOnline', value: true },
            },
            {
              text: 'Talk Now',
              answer: 'callBranch',
            },
          ],
          clicked: false,
        },
      ],
      otherBenefits: [
        {
          type: 'text',
          content: 'No',
          from: 'user',
        },
        {
          type: 'buttons',
          content: `<span class="font-bold">Is there another benefit you wish you had?</span>`,
          from: 'emily',
          answers: [
            {
              text: 'Dental',
              answer: 'helpWithBenefit',
              event: { property: 'benefit', value: 'Dental' },
            },
            {
              text: 'Premium Giveback',
              answer: 'helpWithBenefit',
              event: { property: 'benefit', value: 'Premium Giveback' },
            },
            {
              text: 'Premium Giveback',
              answer: 'helpWithBenefit',
              event: { property: 'benefit', value: 'Premium Giveback' },
            },
            {
              text: 'Something Else',
              answer: 'helpWithBenefit',
              event: { property: 'benefit', value: 'Something Else' },
            },
          ],
          clicked: false,
        },
      ],
      helpWithBenefit: [
        {
          type: 'text',
          content: selectedAnswers.value?.benefit,
          from: 'user',
        },
        {
          type: 'buttons',
          content: `<span class="font-bold">I can help you with ${selectedAnswers.value?.benefit} - do you have a minute to chat on the phone?</span>`,
          from: 'emily',
          answers: [
            {
              text: 'Yes 👍',
              answer: 'callBranch',
            },
            {
              text: 'No',
              answer: 'enrollOnline',
            },
          ],
          clicked: false,
        },
      ],
      enrollOnline: [
        {
          type: 'text',
          content:
            selectedAnswers.value?.wantToShopOnline &&
            selectedAnswers.value?.wantToShopOnline === true
              ? 'Shop Online'
              : 'No',
          from: 'user',
        },
        {
          type: 'text',
          content: `No Problem!`,
          from: 'emily',
        },
        {
          type: 'text',
          content: `I've found a few options that you can explore online`,
          from: 'emily',
        },
        {
          type: 'redirectButton',
          content: `Shop Plans`,
          from: 'emily',
          ctaUrl: '/ads',
        },
      ],
    },
    configuration: {
      id: 'shop4DsnpOtcChat',
      initialBlockId: 'init',
      reminder: {
        reminderBlockId: 'reminder',
        reminderTimeout: 6000,
      },
    },
  } as Content
})

const router = useRouter()
const redirect = () => {
  router.push('/shop-4-dsnp-otc-2024')
}
</script>

<template>
  <Layout
    :hide-header="true"
    :hide-footer="true"
    class="bg-gradient-to-b from-white to-gray-100 dark:from-[#1f1f1f] dark:to-[#2d2d2d]"
  >
    <button
      @click="redirect()"
      class="fixed text-xs top-2 right-2 border border-black p-1 rounded bg-white"
    >
      <Icon i-heroicons-solid:x />
    </button>
    <template #banner>
      <CountdownHeader />
    </template>
    <Chat
      v-if="content && !loading && !error"
      :content="content"
      :headline="chatHeadline"
      @on-select-answer="onSelectAnswer"
      :get-message-block-by-id="getMessageBlockById"
    />
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bridge", "bh", "m10"],
    "robots": "noindex"
  }
}
</route>
