<script setup lang="ts">
const { onSubmit } = inject('form') as any

const phrases = [
  'Revisando tus respuestas…',
  'Emparejándote con las mejores opciones…',
  'Confirmando elegibilidad…',
]

const { state, index, next } = useCycleList(phrases)

useIntervalFn(() => {
  if (index.value === phrases.length - 1) onSubmit()
  else next()
}, 1500)
</script>

<template>
  <FormProgress class="!mb-6" />

  <div>
    <Transition name="fade" mode="out-in">
      <div :key="state" class="text-3xl sm:text-4xl text-center">
        {{ state }}
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-500;
}

.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
</style>
