<script setup lang="ts">
import type { Ref } from 'vue'

const { onSubmit } = inject<any>('form')

useTimeoutFn(() => {
  onSubmit()
}, 2000)

const lowBid = inject('lowBid') as Ref<boolean>

const afid = computed(() => {
  return typeof phoneConfig.value === 'object' && 'afid' in phoneConfig.value
    ? phoneConfig.value.afid
    : undefined
})

const isUnmounted = ref(false)

onUnmounted(() => {
  isUnmounted.value = true
})

onMounted(async () => {
  if (placementId.value && query.utm_source?.toLowerCase() === 'facebook') {
    lowBid.value = await callTransferCheck(isUnmounted.value, afid)
  }
})
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-3xl sm:text-4xl text-center">
    Searching for plans in your area...
  </div>

  <div class="flex justify-center">
    <Spinner />
  </div>
</template>
