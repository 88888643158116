<script setup lang="ts">
import testimonial1 from '../../assets/testimonial-1.jpeg'
import testimonial2 from '../../assets/testimonial-2.jpeg'
import { DateTime } from 'luxon'

useHead({
  title: `Llama a ${brand.name} para saber más`,
})

const source = query.utm_source?.toLowerCase()

if (source === 'google') {
  usePhone({
    placementId: 'UTf2NGR4KbVIwllq9kI6xh7ooZUgMA',
    default: '18885689485',
    afid: '811246',
  })
}

if (source === 'facebook') {
  usePhone({
    placementId: 'vaA5IpUf0FKvVFpjHesGe6qIQUl-sA',
    default: '18888062064',
    afid: '811247',
  })
}

enterExperiment('testimonials')

const date = ref()

onMounted(() => {
  date.value = DateTime.now().toFormat('d MMMM y')
})

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})
</script>

<template>
  <Layout hideHeader class="container">
    <div class="max-w-3xl mx-auto space-y-6 pt-4 pb-6">
      <div class="text-lg leading-6 text-center text-gray-600 -mb-4">
        {{ date }}
      </div>

      <div class="text-xl leading-6.5 sm:text-3xl font-bold text-center">
        <template v-if="isPaidUser">
          ¿64+ en {{ ip.lookup?.stateName || 'América' }}? Puedes calificar para
          obtener $900 para usarlos en el supermercado
          <span class="text-red">
            con varios planes de Medicare Advantage
          </span>
        </template>
        <template v-else>
          ¿Tienes 64 años o más en {{ ip.lookup?.stateName || 'América' }}?
          Podrías calificar para obtener dinero para usar en el supermercado
          <span class="text-red">
            con varios planes de Medicare Advantage
          </span>
        </template>
      </div>

      <img
        src="../../assets/grocery.jpg"
        class="w-[90%] sm:w-[400px] mx-auto"
      />

      <div
        class="py-6 px-4 -mx-4 [box-shadow:0_0_6px_rgba(0,0,0,0.3)] sm:rounded sm:px-6 sm:mx-0"
      >
        <Form
          id="healthyFoodsEs"
          class="!max-w-3xl"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'medicare' },
            { id: 'us' },
            { id: 'medicaid' },
            { id: 'loading' },
            { id: 'call' },
          ]"
          product="medicare"
        />
      </div>

      <Testimonials
        v-if="exp?.testimonials?.show"
        section-title="Testimonios"
        :testimonials="[
          {
            photo: testimonial1,
            name: 'John Peterson',
            text: 'Laura fue de gran ayuda. Me explicó con detalle mi nuevo plan de salud. Ahora, tengo un plan que es igual que el antiguo, pero aún mejor. ¡Lo mejor de todo es que incluye cobertura dental! Estoy muy contento de no tener que preocuparme por mis dientes. ¡Gracias, Laura y Humana!',
          },
          {
            photo: testimonial2,
            name: 'Mary Sullivan',
            text: 'Steven me explicó con mucha claridad mi plan de Medicare Advantage. Fue muy amable y estoy emocionada con la característica de reembolso de la Parte B de mi plan. Es reconfortante saber que recibiré dinero de vuelta. ¡Gracias, Steven!',
          },
        ]"
      />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10", "bh", "med10", "boomer"],
    "smid": "MULTI-PLAN_EHWEB062302_M",
    "xsmid": "MULTI-PLAN_EHWEB062302_M",
    "language": "spanish",
    "robots": "noindex"
  }
}
</route>
