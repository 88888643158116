<script setup lang="ts">
const { value: wantGrocery } = useField('wantGrocery')

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state && state.minOtc !== null && state.minOtcLtd) {
    return {
      code: state.code,
      name: state.name,
      minOtc: `$${state.minOtc}`,
      minOtcLtd: `$${state.minOtcLtd}`,
    }
  }
})
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-3xl sm:text-4xl text-center">
    <template v-if="state">
      Do you want {{ state.minOtcLtd }}/yr or more to help pay for groceries and
      over the counter products?
    </template>
    <template v-else>
      Do you want to shop for plans with additional benefits?
    </template>
  </div>

  <div
    class="grid grid-cols-2 gap-4"
    v-if="exp?.groceryAllowance2024FacebookGoogle?.newVersion"
  >
    <Button
      wide
      size="sm"
      type="submit"
      variant="undefined"
      :class="['bg-[#00B400] active:bg-[#008900] border-[#00B400] text-white']"
      @click="wantGrocery = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="undefined"
      :class="['bg-[#EA0000] active:bg-[#ba0101] border-[#EA0000] text-white']"
      @click="wantGrocery = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="wantGrocery = null"
    >
      Skip
    </Button>
  </div>
  <div class="grid grid-cols-2 gap-4" v-else>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="wantGrocery = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="wantGrocery = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="wantGrocery = null"
    >
      Skip
    </Button>
  </div>
</template>
