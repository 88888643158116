<script setup lang="ts">
import bhLogo from '~/assets/logo-bh.png'
import m10Logo from '~/assets/logo-m10.png'

useHead({
  title: `Comprar Planes de Medicare - ${brand.name}`,
})

analytics.page({
  name: 'MediaAlpha Ads ES',
  category: 'LP',
  product: 'medicare',
})
</script>

<template>
  <Layout footer="2024" class="container">
    <template #banner>
      <Banner> ¿Nuevo en Medicare? ¿En Medicaid? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        Comprar Planes de Medicare en Línea
      </div>

      <div class="max-w-3xl mx-auto space-y-6">
        <MediaAlphaAd
          :placementId="'-TsPyhhabOnoDcgHe55M4NOSogciBA'"
          :user="{}"
        />
      </div>

      <HouseAds
        :ads="[
          {
            url: 'https://benefit-helpline.com/get-yours-x4c',
            logo: bhLogo,
            star: true,
            verified: true,
            title: '$500 ASIGNACIÓN DE GASTOS EN COMESTIBLES',
            items: [
              'Puede ser elegible para $500 o más, al año en una asignación para comestibles con un Plan Medicare Advantage D-SNP.',
            ],
            button: 'Shop Online',
          },
          {
            url: 'https://medicare10.com/additional-benefits-buyback-2024',
            logo: m10Logo,
            star: true,
            verified: true,
            title: 'Recibe $420 o más en tu cheque del Seguro Social',
            items: [
              'Podrías recibir dinero de vuelta en tu cheque del Seguro Social con algunos planes de Medicare Advantage de conocidas compañías como Humana y UnitedHealthcare®.',
            ],
            button: 'Shop Online',
          },
        ]"
      />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "m10", "med10", "bridge"],
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0524176_C",
      "m10": "MULTI-PLAN_HLWEB0524175_C"
    },
    "robots": "noindex"
  }
}
</route>
