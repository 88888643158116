<script setup lang="ts">
const isProd = import.meta.env.MODE === 'production'
const router = useRouter()

const start = Date.now()
const now = useNow()

const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)

const minOtcLtd = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  )
})

const top5Otc = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.top5Otc ?? 500
  )
})

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})

const redirectToPostChat = (ms: number) => {
  enterExperiment('postCallOnGrocery2024')
  if (exp.value?.postCallOnGrocery2024?.shouldRedirect) {
    router.push('/post-call-chat')
  }
}
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-2xl sm:text-3xl text-red text-center">
    Congratulations!
  </div>

  <template v-if="isPaidUser">
    <div class="text-3xl sm:text-4xl text-center">
      Looks like you may qualify for ${{ minOtcLtd }} per year or more to spend
      on groceries!
    </div>
  </template>
  <template v-else>
    <div class="text-3xl sm:text-4xl text-center">
      Finish up with a licensed insurance agent.
    </div>
  </template>

  <ClickToCall
    :props="{ ctcLocation: 'last-step' }"
    tty
    @return="redirectToPostChat"
  />

  <div
    v-if="isPaidUser && isProd"
    class="text-lg sm:text-xl font-medium text-center"
  >
    Act quickly: <span class="text-red"> {{ timer }} </span>
  </div>
</template>
