<script setup lang="ts">
import type { Ref } from 'vue'

const variant = inject('variant') as Ref<string | undefined>

const { value: wantBenefit } = useField('wantBenefit')
</script>

<template>
  <FormProgressSteps />

  <div class="text-3xl sm:text-4xl text-center">
    <template v-if="variant === 'default'">
      Do you want to shop for plans with additional benefits?
    </template>
    <template v-if="variant === 'groceryAllowance900'">
      Do you want up to $900 to use towards groceries?
    </template>
    <template v-if="variant === 'spendingAllowances3300'">
      Do you want up to $3,300 in spending allowances?
    </template>
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="wantBenefit = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="wantBenefit = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="wantBenefit = null"
    >
      Prefer not to say
    </Button>
  </div>
</template>
