<script setup lang="ts">
const { value: liveInUs } = useField('liveInUs')
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-3xl sm:text-4xl text-center">
    Do you live in {{ ip.lookup?.stateName || 'America' }}?
  </div>

  <div
    class="grid grid-cols-2 gap-4"
    v-if="exp?.groceryAllowance2024FacebookGoogle?.newVersion"
  >
    <Button
      wide
      size="sm"
      type="submit"
      variant="undefined"
      :class="['bg-[#00B400] active:bg-[#008900] border-[#00B400] text-white']"
      @click="liveInUs = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="undefined"
      :class="['bg-[#EA0000] active:bg-[#ba0101] border-[#EA0000] text-white']"
      @click="liveInUs = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="liveInUs = null"
    >
      Skip
    </Button>
  </div>
  <div class="grid grid-cols-2 gap-4" v-else>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="liveInUs = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="liveInUs = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="liveInUs = null"
    >
      Skip
    </Button>
  </div>
</template>
