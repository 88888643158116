<script setup lang="ts">
useHead({
  title: `Grocery benefit in your area - ${brand.name}`,
})

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})

const isMobile = ref(true)

onMounted(() => {
  isMobile.value = window.innerWidth < 640
})

const showDefaultExperience = computed(() => {
  return !isMobile.value && import.meta.env.MODE === 'production'
})

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state && state.minOtc !== null && state.minOtcLtd) {
    return {
      code: state.code,
      name: state.name,
      minOtc: `$${state.minOtc}`,
      minOtcLtd: `$${state.minOtcLtd}`,
    }
  }
})

const lowBid = ref(false)

provide('lowBid', lowBid)
</script>

<template>
  <Layout
    header="simple3"
    :hideHeader="isMobile"
    footer="grocery2024"
    class="container"
  >
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        <template v-if="showDefaultExperience">
          Are you shopping for Medicare Insurance plans? A licensed insurance
          agent can check your eligibility.
        </template>
        <template v-else-if="state">
          Some Medicare members in {{ state.name }} may qualify for
          <span class="text-red"> {{ state.minOtcLtd }}/yr or more </span>
          in Allowance* to use towards groceries...
        </template>
        <template v-else>
          Medicare Members could get
          <span class="text-red"> money </span>
          to use on groceries or over-the-counter benefits...
        </template>
      </div>

      <div class="-mx-4 sm:mx-0">
        <img
          src="../../assets/grocery-2024.jpeg"
          width="1100"
          height="576"
          class="w-full sm:w-[400px] mx-auto"
        />
        <div
          v-if="state"
          class="sm:w-[400px] mx-auto bg-black text-gray-200 py-2 px-4 text-center text-lg"
        >
          with some Medicare Advantage plans like
          <span class="text-yellow-500"> Humana and UnitedHealthcare® </span>
        </div>
      </div>

      <Form
        id="groceryRange"
        class="!max-w-3xl"
        scrollBehavior="auto"
        :steps="[
          { id: 'medicareab' },
          { id: 'medicaid', if: !exp?.myOtcMedicareQuestion?.medicare },
          { id: 'us', if: false },
          { id: 'wantGrocery', if: !exp?.myOtcMedicareQuestion?.medicare },
          { id: 'loading' },
          { id: 'call', completed: true },
        ]"
        product="medicare"
      />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["otc"],
    "brands": ["bh", "med10", "m10", "boomer"],
    "smid": "MULTI-PLAN_EHWEB062302_M",
    "xsmid": "MULTI-PLAN_EHWEB062302_M",
    "robots": "noindex"
  }
}
</route>
