<script setup lang="ts">
import discoverImage1 from '../../assets/discover-ads-1.webp'
import discoverImage2 from '../../assets/discover-ads-2.webp'
import discoverImage3 from '../../assets/discover-ads-3.webp'
import discoverLogo1 from '../../assets/discover-ads-logo-1.webp'
import discoverLogo2 from '../../assets/discover-ads-logo-2.webp'
import discoverLogo3 from '../../assets/discover-ads-logo-3.webp'

const router = useRouter()

onMounted(() => {
  const isPaidUser = getSessionEnhanced().isPaidUser

  if (!isPaidUser) {
    router.push('/go-4-benefits')
  }
})
type DiscoverAd = {
  headline: string
  company: {
    name: string
    logo: string
  }
  image: string
  ctaUrl: string
}

const ads: DiscoverAd[] = [
  {
    headline: 'Discover the benefits of Medicare Plan A',
    company: {
      name: 'MediCare A',
      logo: discoverLogo1,
    },
    image: discoverImage1,
    ctaUrl: '/medicare-A-bridge/discover',
  },
  {
    headline: 'Experience comprehensive care with Medicare Plan B',
    company: {
      name: 'MediCare B',
      logo: discoverLogo2,
    },
    image: discoverImage2,
    ctaUrl: '/medicare-B-bridge/discover',
  },
  {
    headline: 'Stay healthy and save money with Medicare Plan C',
    company: {
      name: 'MediCare C',
      logo: discoverLogo3,
    },
    image: discoverImage3,
    ctaUrl: '/medicare-C-bridge/discover',
  },
]

const onClick = (url: string) => {
  router.push(url)
}
</script>

<template>
  <Layout
    class="container flex flex-col gap-8 items-center justify-center"
    footer="2024"
  >
    <div
      v-for="(ad, index) in ads"
      :key="index"
      :class="[
        'rounded-lg flex flex-col gap-4 cursor-pointer',
        index === 0 ? 'pt-8' : '',
      ]"
      @click="onClick(ad.ctaUrl)"
    >
      <img :src="ad.image" alt="Ad image" class="rounded-lg max-w-96 w-full" />
      <h2 class="text-lg whitespace-break-spaces">{{ ad.headline }}</h2>
      <div class="flex gap-2 items-center">
        <img :src="ad.company.logo" alt="Company logo" class="w-5" />
        <p class="text-xs">{{ ad.company.name }}</p>
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "med10", "boomer", "m10"],
    "robots": "noindex"
  }
}
</route>
