<script setup lang="ts">
analytics.page({
  name: 'Groceries Bridge 1',
  category: 'LP',
  product: 'medicare',
})

const router = useRouter()

onMounted(async () => {
  const isPaidUser = getSessionEnhanced().isPaidUser
  if (!isPaidUser) {
    router.push('/go-4-benefits')
  }
})
</script>

<template>
  <Layout
    :hideHeader="true"
    class="bg-yellow-300 flex flex-col min-h-[100vh] p-8 gap-6 md:gap-12 font-dm items-center"
    footer="2024"
  >
    <div
      :class="[
        'text-2xl font-bold mb-4 text-center md:my-8 my-4',
        'md:text-6xl md:max-w-3xl',
      ]"
    >
      Get money for groceries with Medicare Advantage!
    </div>
    <div class="image-wrapper">
      <img src="../../assets/groceries-ad.jpg" alt="Groceries" class="image" />
    </div>

    <div class="text-lg md:text-xl md:max-w-3xl text-center font-semibold">
      Keep your fridge and wallet full with our help. Find out how you can get
      money for groceries just by signing up for some Medicare Advantage plan.
    </div>

    <div class="text-lg md:max-w-3xl md:w-full flex justify-center my-4">
      <Button to="/additional-benefits-grocery-2024" class="font-sans">
        <span> Learn More </span>
        <Icon i-heroicons-solid:arrow-right class="w-8 h-8 ml-4" />
      </Button>
    </div>
  </Layout>
</template>

<style scoped>
.image-wrapper {
  @apply h-60 w-full mx-auto border-8 border-white overflow-hidden md:h-[760px] md:w-[700px];
  border-radius: 48px;
  border-top-left-radius: 60%;
  border-top-right-radius: 60%;
  @media screen and (min-width: 768px) {
    border-radius: 20%;
    border-top-left-radius: 60%;
    border-top-right-radius: 60%;
  }
}
.image {
  transform: scale(1.7) translateY(30px);

  @media screen and (min-width: 768px) {
    transform: scale(1.9) translateY(110px);
  }
}
</style>
<route lang="json">
{
  "meta": {
    "brands": ["bh", "med10", "m10", "boomer"],
    "robots": "noindex"
  }
}
</route>
