<script setup lang="ts">
const { value: haveMedicare } = useField('haveMedicare')
</script>

<template>
  <div class="text-red flex items-center justify-center gap-2">
    <span class="text-2xl sm:text-3xl"> See if you qualify </span>
    <Icon i-heroicons-solid:arrow-down />
  </div>

  <div class="text-3xl sm:text-4xl text-center"> Do you have Medicare? </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicare = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="haveMedicare = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="haveMedicare = null"
    >
      Skip
    </Button>
  </div>
</template>
