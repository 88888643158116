<script setup lang="ts">
import { ref, onMounted, onUnmounted, nextTick } from 'vue'

const isSticky = ref(false)
const stickyDiv = ref<HTMLElement | null>(null)
let originalPosition = 0

const handleScroll = () => {
  if (stickyDiv.value) {
    const stickyBottom = stickyDiv.value.getBoundingClientRect().bottom
    const buttonHeight = stickyDiv.value.getBoundingClientRect().height
    const scrollTop =
      (window.scrollY || window.pageYOffset) + window.innerHeight - buttonHeight
    const isOriginalPosition = scrollTop > originalPosition + buttonHeight

    isSticky.value =
      isOriginalPosition && stickyBottom <= window.innerHeight - 16
  }
}

onMounted(async () => {
  await nextTick()
  if (stickyDiv.value) {
    const getRect = stickyDiv.value.getBoundingClientRect()
    originalPosition = getRect.top + window.scrollY
  }
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>
<template>
  <div
    ref="stickyDiv"
    :class="[
      'text-lg  flex my-4 w-full z-2 justify-center self-center',
      isSticky ? 'fixed bottom-4' : '',
    ]"
  >
    <ClickToCall
      v-slot="{ handleClick }"
      :props="{ ctcLocation: 'sticky-ctc' }"
    >
      <Button
        :to="phone.uri"
        @click="handleClick"
        variant="undefined"
        :class="[
          'bg-blue-600  text-white py-2 !rounded-full shadow-md',
          isSticky ? 'mx-6 md:mx-0' : '',
        ]"
      >
        <span class="relative block w-10 h-10 mr-6">
          <span class="absolute inset-0 flex items-center justify-center">
            <span :class="['bg-white/30', 'animate-ping w-8 h-8 rounded-full']">
            </span>
          </span>
          <span class="absolute inset-0 flex items-center justify-center">
            <Icon
              i-heroicons-solid:phone
              :class="['text-white', 'w-10 h-10 animate-shake']"
            />
          </span>
        </span>
        <span>
          {{ isMobile ? 'Call now' : phone.formatted }}
        </span>
      </Button>
    </ClickToCall>
  </div>
  <div v-if="isSticky" class="h-24"> &nbsp;</div>
</template>
