<script setup lang="ts">
import audicus from '../../assets/logos/audicus.svg'
import jabra from '../../assets/logos/jabra-enhance-logo.svg'
import mdhearing from '../../assets/logos/MDHearing-Logo_RGB-Color_500x90.png'
import eargo from '../../assets/logos/eargo-logo.svg'

const hearingAids = [
  {
    name: 'Jabra Enhance',
    logo: jabra,
    score: '9.9',
    benefits: [
      '360-degree sound & bluetooth enabled',
      '100-day risk-free trial',
      'Nearly invisible with a sleek design',
    ],
    cta: {
      label: "On Jabra's website",
      url: 'https://google.com',
    },
  },
  {
    name: 'Audicus',
    logo: audicus,
    score: '9.8',
    benefits: [
      'Custom-programmed hearing aids without the markups',
      'Dedicated U.S.-based licensed hearing professionals available at every step',
      'Audicus Premier plan includes replacements every 18 months',
    ],
    cta: {
      label: "On Audicus's website",
      url: 'https://google.com',
    },
  },
  {
    name: 'MDHearing',
    logo: mdhearing,
    score: '9.8',
    benefits: [
      'Starting at $297 per pair, thousands less than in-clinic hearing aids',
      'Dedicated U.S.-based licensed hearing professionals available at every step',
      'All hearing aids come with free shipping and a 45-day trial period',
    ],
    cta: {
      label: "On MDHearing's website",
      url: 'https://google.com',
    },
  },
  {
    name: 'Eargo',
    logo: eargo,
    score: '9.7',
    benefits: [
      'Nearly invisible and sold as a pair',
      'Adjust hearing aid settings for the environment',
      'Noise and wind reduction on most models',
    ],
    cta: {
      label: "On Eargo's website",
      url: 'https://google.com',
    },
  },
]

type Ad = {
  image: string
  title: string
  subtitle: string
  cta: string
  ctaSubtitle: string
  url: string
}
const ad: Ad = {
  image: jabra,
  title: 'SKIP THE WAITING ROOM',
  subtitle: 'Advanced Hearing Aids, Expert Care & 100-Day Trial',
  cta: 'Shop Jabra Enhance',
  ctaSubtitle: "On Jabra's Website",
  url: 'https://www.jabra.com/hearing',
}

type Topic = {
  title: string
  content: string
  ad?: Ad
}

type QA = {
  topics: Topic[]
}

const qa: QA[] = [
  {
    topics: [
      {
        title: 'What Is a Hearing Aid?',
        content: `<p>A hearing aid is a small electronic device that helps compensate for (but not cure) mild to profound hearing loss. 
            Hearing aids come in a wide variety of styles and sizes and can be worn by people of all ages struggling with hearing loss.</p>
          <p>Hearing aids are medical devices regulated by the Food and Drug Administration (FDA). They are fit and sold by state-licensed hearing care 
            professionals—audiologists typically have the most training with them. A handful of manufacturers dominate the market and produce hundreds of 
            ypes of aids with various components and technology levels.</p>`,
      },
      {
        title: 'How Do Hearing Aids Work?',
        content: `<p>A hearing aid typically contains a microphone, amplifier and speaker. The microphone picks up acoustic sound waves and converts them into 
            electrical signals, which are then processed and enhanced by the amplifier. Those signals are then sent to the speaker, which converts 
            them back into sound waves, providing a louder and clearer sound for the wearer. This method of sound amplification can help improve a person’s 
            hearing and make speech from others easier to understand.</p>`,
      },
    ],
  },
  {
    topics: [
      {
        title: 'How Much Do Hearing Aids Cost?',
        content: `<p>Hearing aid prices can vary dramatically depending on the manufacturer of the aid, the model you choose, the additional technology 
          features you select and the audiologist from whom you purchase them. Most hearing aids are priced individually, so if you have hearing loss 
          in both ears, remember to double the prices you see listed by hearing aid retailers. Taking top manufacturers and models into consideration, 
          starting prices for a pair of hearing aids in 2022 ranged from about $1,400 to over $5,000.</p>`,
      },
    ],
  },
  {
    topics: [
      {
        title: 'Hearing Aid Types',
        content: `<p>There are many different types and styles of hearing aids available to best suit the wearer, their level of hearing loss, 
          their fit preferences and their physical abilities (such as finger dexterity needed for replacing batteries). Some hearing aid devices sit outside 
          the ear, others nestle completely in the ear, and several types have both inner and outer ear elements.</p>
          <p>Four basic types of hearing aids include:</p>
          <ul class="space-y-4">
            <li>
              <strong>Behind-the-ear (BTE) hearing aids,</strong> which house all tech components behind the ear in a casing that connects to the inside of 
              the outer ear via a thin tube to a dome or ear mold. A common style is a mini-behind-the-ear (mBTE) or a slimmer BTE.
            </li>
            <li>
              <strong>Receiver-in-canal (RIC) hearing aids,</strong> which are also worn behind the ear but connect via a form-fitted wire to a small speaker that sits in the ear canal.
            </li>
            <li>
              <strong>In-the-ear (ITE) hearing aids, </strong> which are nearly hidden inside the ear canal with no components behind the ear.
            </li>
            <li>
              <strong>Completely-in-canal (CIC) hearing aids,</strong> which are custom-made to sit entirely inside the ear canal.
            </li>

          </ul>
          <p>Read our in-depth guide to different types of hearing aids for more information.</p>`,
      },
    ],
  },
  {
    topics: [
      {
        title: 'Over-the-Counter (OTC) Hearing Aids',
        content: `<p>According to a new rule from the FDA, over-the-counter (OTC) hearing aids may also be available for adults with 
          perceived mild to moderate hearing loss as soon as October 2022. These hearing aids are expected to be significantly more 
          affordable than current prescription options and will be available at stores and online retailers (who aren’t required to be 
          licensed sellers). Users will not require a medical exam, prescription or fitting adjustment by an audiologist or hearing health 
          professional to purchase these hearing aids.</p>
        <p>According to panelist Abram Bailey, an audiologist and Forbes Health Advisory Board member, Audicus, Bose, Eargo, Jabra, 
          Lexie and Lively are all brands to keep an eye on as the OTC hearing aid market solidifies.</p>`,
      },
    ],
  },
  {
    topics: [
      {
        title: 'Benefits of Hearing Aids',
        content: `<p>Only one in five people who would benefit from a hearing aid uses one, according to the Hearing Loss Association 
            of America[1]. Cost, confusion about and access to hearing care, and gradual hearing loss going unrecognized are all reasons 
            for this underutilization. Vanity also plays a role, though hearing aids continue to become smaller and sleeker.</p>
          <p>“Having hearing loss is like you’re backing out 10 feet from a conversation,” says John Coverstone, an audiologist in New 
            Brighton, Minnesota, and host of the AudiologyTalk podcast. “People feel more isolated,” he says.</p>
          <p>Studies link trouble hearing with serious health consequences. Untreated hearing loss increases your risk of anxiety, 
              depression, social isolation, falls and cognitive decline, research shows.</p>
          <p>Proper hearing aids can greatly improve hearing and, consequently, health, studies find. For example, wearing hearing aids appears 
            to reduce the “excess risk” of dementia from hearing loss, according to a 2020 report in The Lancet[2].</p>`,
        ad: ad,
      },
    ],
  },
  {
    topics: [
      {
        title: 'How to Choose a Hearing Aid for You',
        content: `<p>The best hearing aid for you depends on your level of hearing loss, your lifestyle 
          and your fit preferences. First, consult a hearing health professional, such as an audiologist, 
          for a hearing examination to determine your level of hearing loss. From there, they can help you 
          review specific hearing aid models most appropriate for your situation and pick the one you’d 
          like to try.</p>
          <h3 class="font-semibold">Finding the Best Hearing Aid Fit</h3>
          <p>With hearing aids that house some or all components behind the ear, two kinds of parts go in the ear—an open-fit 
            dome or a closed-fit mold. Many people like the feel of an open-fit dome as it lets some hearing pass in naturally. 
            A closed fit, custom-made ear mold may result in the best hearing but users have to adjust to the “stopped up” feel.</p>
          <p>The most important factors for hearing aid success are acoustic fit and physical fit, says audiologist Catherine 
            Palmer, former president of the American Academy of Audiology and director of audiology and hearing aids at the 
            University of Pittsburgh Medical Center.</p>
          <p>Acoustic fit is how well an audiologist programs the aids to your specific hearing needs. Physical fit has to do 
            with how well they fit in your ear, which affects both hearing and comfort.</p>
          <p>A great hearing aid fit hinges on three things:</p>
          <p><strong>Your type of hearing loss</strong>. Many people lose hearing in the high frequencies first. Others have difficulty across all 
            frequencies. Hearing tests measure sensitivity, or how loud a sound has to be to be heard, and clarity, or 
            how well someone can understand speech. Often, you’ll also get a “speech in noise” test. These results create a 
            sound prescription for the best hearing aid for you.</p>
          <p><strong>Your lifestyle</strong>. Do you work? Do you love dining in restaurants? Do you listen to podcasts? Your lifestyle suggests 
            which features, such as Bluetooth connectivity, are important. Also, certain hearing aid styles, such as behind-the-ear 
            or completely-in-the-canal are better or worse for certain situations.</p>
          <p><strong>Your dexterity</strong>. If you have trouble manipulating batteries, a rechargeable hearing aid is often recommended.</p>
          <p>Based on these findings and your budget, your hearing professional will recommend style, technology level and 
            features. “At the end of the day, the best hearing aid is the one the person will wear,” says Palmer.</p>
          <p>Once your hearing aid is programmed and customized for you, real-ear measurements are typically performed. This is an 
            important test to ensure our hearing aids are meeting your sound prescription. At this point or later checkups, an audiologist 
            may also recommend accessories such as a clip-on “remote microphone,” which can dramatically help you hear one particular person.</p>
            <h3 class="font-semibold">Features to Consider</h3>
            <p>Hearing aids can also come with a variety of features, including (but not limited to):</p>
            <ul class="space-y-4">
              <li>A directional microphone that makes conversing in noisy environments easier</li>
              <li>A telecoil (t-coil) that amplifies a speaker’s voice over background noise in public places</li>
              <li>Remote microphones</li>
              <li>Feedback suppression technology, which reduces acoustic feedback (often experienced with phones)</li>
              <li>Impulse noise and wind reduction technologies</li>
              <li>Rechargeable batteries</li>
              <li>Wireless connectivity with Bluetooth-compatible devices</li>
              <li>Remote controls for easy adjustments</li>
              <li>Preprogrammed settings for different listening needs and environments</li>
            </ul>
          `,
      },
    ],
  },
  {
    topics: [
      {
        title: 'Hearing Aid Tips',
        content: `<p>We talked to hearing aid user Katherine Bouton, author of Smart Hearing: Strategies, 
          Skills and Resources for Living Better With Hearing Loss. She has these three top tips for hearing aid users:</p>
        <ol class="space-y-4 list-decimal pl-4" >
          <li>
            <strong>Wear your hearing aids constantly (except when you sleep), especially for the first month</strong>. “Your brain is 
            learning to hear new sounds—you need to train it well by wearing the aids full-time.”
          </li>  
          <li>
              <strong>Schedule in-person or telecare follow-up visits to your audiologist</strong>. “Your audiologist can reprogram your 
              hearing aids to help them keep up with your hearing, or you can return your hearing aids if you’re not happy and try something else.”
           </li>  
        </ol>`,
      },
    ],
  },
  {
    topics: [
      {
        title: 'Frequently Asked Questions (FAQs)',
        content: `<p>What level of hearing loss requires a hearing aid?</p>
          <p class="font-semibold">There are five levels of hearing loss, ranging from mild to profound, according to the Hearing Health Foundation (HHF). 
            If you have mild to moderate hearing loss, you may be eligible for personal sound amplification products (PSAPs) or 
            hearing aids, depending on an individual evaluation conducted by a hearing health professional.</p>
          <p class="font-semibold">How do you clean hearing aids?</p>
          <p>It depends on the type of hearing aid you wear, but generally, you can wipe the devices with a damp cloth to remove any larger debris. 
            Then, use a small, gentle brush tool to clean ear wax out of any small holes on the device where it could build up over time.</p>`,
        ad: ad,
      },
    ],
  },
]
</script>
<template>
  <Layout class="container">
    <a
      href="#main-content"
      class="sr-only focus:not-sr-only focus:block focus:text-center focus:bg-gray-100 focus:p-4 focus:text-lg"
    >
      Skip to main content
    </a>

    <div class="flex flex-col gap-4 py-4">
      <h1 class="text-2xl md:text-4xl font-semibold">
        Best Hearing Aids Of 2023
      </h1>

      <div class="text-base md:text-lg">
        HealthyLabs reviewed the best hearing aids available on the market to
        help guide your hearing aid choice. See our top picks.
      </div>

      <section class="flex flex-col gap-6" id="main-content">
        <div
          class="hearing-aid-card"
          v-for="(hearingAid, index) in hearingAids"
          :key="hearingAid.name"
        >
          <div
            class="hearing-aid-highlight flex gap-4 items-center"
            v-if="index === 0"
          >
            <div
              class="most-popular flex gap-2 sm:gap-4 bg-red text-white rounded-md p-2 text-sm sm:text-base"
            >
              <Icon i-heroicons-outline:check />
              <span class="hidden sm:inline">Most Popular</span>
            </div>
            <Icon
              i-heroicons-outline:star
              class="text-amber-400 hidden sm:block"
            />
          </div>
          <div
            :class="[
              'hearing-aid-position flex gap-4 items-center',
              index > 0 && 'pt-4 sm:pt-6',
            ]"
          >
            <div class="text-3xl md:text-5xl">{{ index + 1 }}</div>
            <img
              :src="hearingAid.logo"
              class="w-20 md:w-40"
              :alt="`${hearingAid.name} logo`"
            />
          </div>
          <div :class="['hearing-aid-score', index > 0 && 'pt-4 sm:pt-6']">
            <div class="rounded-md flex border border-gray-300 w-full sm:w-44">
              <div
                class="text-base font-semibold flex items-center justify-center text-white bg-red p-2 rounded-l-md w-14 sm:w-auto"
              >
                {{ parseFloat(hearingAid.score).toFixed(1) }}
              </div>
              <div
                class="flex flex-col py-1 items-center justify-center w-full"
              >
                <div class="uppercase text-xs sm:text-sm">Excellent</div>
                <div class="flex">
                  <RatingStars
                    :rating="parseFloat(hearingAid.score)"
                    :max-range="10"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="hearing-aid-cta">
            <a
              :href="hearingAid.cta.url"
              class="block p-4 bg-red text-center text-white text-lg sm:text-xl font-semibold rounded-md w-full sm:w-44 hover:bg-red-600 focus:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Shop Now
            </a>
            <span class="text-sm">{{ hearingAid.cta.label }}</span>
          </div>

          <div class="hearing-aid-benefits">
            <div
              class="flex gap-4"
              v-for="benefit in hearingAid.benefits"
              :key="benefit"
            >
              <div
                class="rounded-full bg-green-100 w-6 h-6 flex items-center justify-center shrink-0"
              >
                <Icon
                  i-heroicons-solid:check
                  class="w-4 text-green-600 font-semibold"
                />
              </div>
              <div>
                {{ benefit }}
              </div>
            </div>
          </div>
        </div>

        <section
          :class="[
            'border-b-4 border-black pb-6 flex flex-col gap-4',
            index === 0 && 'pt-6',
          ]"
          v-for="(section, index) in qa"
          :key="section.topics[0].title + '_' + index"
        >
          <div v-for="topic in section.topics" :key="topic.title">
            <h2 class="text-2xl md:text-3xl font-semibold pb-6">
              {{ topic.title }}
            </h2>
            <div
              class="text-base md:text-lg space-y-4"
              v-html="topic.content"
            />

            <div
              v-if="topic.ad"
              :class="[
                'flex flex-col  border border-gray-300 rounded-lg px-4 py-6 items-center shadow-lg',
                'md:flex-row gap-6 md:gap-12 lg:gap-20 md:justify-center md:items-center mt-6',
              ]"
            >
              <img
                :src="topic.ad.image"
                class="w-40 h-40 md:w-48 md:h-48"
                alt="Hearing aid ad"
              />
              <div
                class="flex flex-col items-center md:items-start gap-4 md:gap-2"
              >
                <h4
                  class="uppercase text-center md:text-start font-semibold text-gray-600 text-sm sm:text-base"
                >
                  {{ topic.ad.title }}
                </h4>
                <h3
                  class="text-center md:text-start text-2xl sm:text-3xl md:text-4xl font-semibold text-red"
                >
                  {{ topic.ad.subtitle }}
                </h3>
              </div>
              <div class="flex flex-col items-center gap-2 flex-grow">
                <a
                  :href="topic.ad.url"
                  class="block p-4 bg-red text-center text-white text-lg font-semibold rounded-md w-full md:w-60 hover:bg-red-600 focus:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  {{ topic.ad.cta }}
                </a>
                <span class="text-xs">{{ topic.ad.ctaSubtitle }}</span>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "robots": "noindex"
  }
}
</route>

<style scoped>
.hearing-aid-card {
  @apply rounded-md border border-gray-300 shadow-lg p-4 gap-4 grid gap-y-4;
  @apply sm:p-6 md:px-8 md:items-center md:justify-center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);

  grid-template-areas:
    'position score'
    'benefits benefits'
    'cta cta';

  @media screen and (min-width: 768px) {
    grid-template-columns: 200px 2fr 176px;
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'position benefits score'
      'position benefits cta';
  }
}
.hearing-aid-card:first-of-type {
  @apply md:gap-y-0;

  grid-template-areas:
    'high high'
    'position score'
    'benefits benefits'
    'cta cta';

  @media screen and (min-width: 768px) {
    grid-template-areas:
      'high benefits score'
      'position benefits cta';
  }
}

.hearing-aid-highlight {
  grid-area: high;
  @apply flex items-center;
  @apply md:h-full;
}

.hearing-aid-position {
  @apply flex h-full items-center;
  @apply md:flex-col md:h-full md:justify-center md:items-start;
  grid-area: position;
}

.hearing-aid-benefits {
  grid-area: benefits;
  @apply flex flex-col gap-2;
  @apply md:h-full md:justify-center md:items-start;
}

.hearing-aid-score {
  grid-area: score;
  @apply flex flex-col gap-4 items-start items-center;
  @apply md:items-center md:justify-center md:h-full;
}
.hearing-aid-cta {
  @apply items-center flex flex-col gap-2;
  grid-area: cta;
}
</style>
