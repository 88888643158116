<script setup lang="ts">
useHead({
  title: `Shop Medicare Plans Online - ${brand.name}`,
})

analytics.page({
  name: 'MediaAlpha Ads 1',
  category: 'LP',
  product: 'medicare',
})
</script>

<template>
  <Layout :hide-header="isMobile" footer="2024" class="container">
    <template #banner>
      <Banner> New to Medicare? On Medicaid? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        Shop Medicare Plans Online
      </div>

      <div class="max-w-3xl mx-auto space-y-6">
        <MediaAlphaAd
          :placementId="
            query.utm_source?.toLowerCase() === 'ma'
              ? 'oLXPHoGj8cfc6Rk20iBxNYto4sr1mw'
              : 'xB51YFMkBb4D9ycHYv9YNkBUyjbbUA'
          "
          :user="{}"
        />
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "m10", "med10", "bridge"],
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0524176_C",
      "m10": "MULTI-PLAN_HLWEB0524175_C"
    },
    "robots": "noindex"
  }
}
</route>
