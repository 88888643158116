<script setup lang="ts">
const loading = ref(false)
const error = ref(false)
const isProd = import.meta.env.MODE === 'production'
const plansLength = ref(0)
const queryPlans = async () => {
  loading.value = true
  error.value = false

  await until(() => ip.lookup).toBeTruthy()
  const zipCode =
    forms.medicarePremiumReductionSimple2096?.zipcode ||
    query.zipcode ||
    ip.lookup?.postalCode

  if (zipCode) {
    try {
      const planData = await getPlans({
        zipCode: zipCode,
        amount: 100,
        starting_from: 0,
      })

      if (planData) {
        plansLength.value = planData.length
      }
    } catch (e) {
      if (!isProd) console.error('[ERROR] Cannot retrieve plans:', { e })
      error.value = false
    } finally {
      loading.value = false
    }
  }
}
onMounted(() => {
  queryPlans()
})
</script>
<template>
  <template v-if="loading && !error">
    <Spinner />
  </template>
  <template v-else-if="!loading && error">
    <div class="text-xl sm:text-2xl text-red text-center font-medium">
      Looks Good!
    </div>
  </template>
  <template v-else>
    <div
      class="text-xl sm:text-2xl text-red text-center font-medium"
      v-if="plansLength === 0"
    >
      No plans found
    </div>
    <div class="text-xl sm:text-2xl text-red text-center font-medium" v-else>
      {{ plansLength }} plans found!
    </div>
  </template>
  <div class="text-xl sm:text-2xl text-center font-medium">
    {{ plansLength === 0 ? `But don't worry!` : '' }} Continue w Licensed
    <span class="whitespace-nowrap">Insurance Agent</span>
  </div>

  <ClickToCall :props="{ ctcLocation: 'last-step' }">
    <template #text>
      Click to Call
      <br />
      TTY 711, 24/7
    </template>
  </ClickToCall>
</template>
