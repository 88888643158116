import { DateTime } from 'luxon'

type PhoneConfig =
  | string
  | {
      placementId: string
      default: string
      afid?: string
      channel?: string
    }
  | {
      replacementNumber: string
      campaignId: string
    }

interface Phone {
  value: string
  formatted: string
  uri: string
}

interface RingPoolNumber {
  [key: string]:
    | undefined
    | {
        value: string | null
        expirationDate: number | null
      }
}

const globalPhone = (language: string): PhoneConfig => {
  const source = query.utm_source?.toLowerCase()
  const medium = query.utm_medium?.toLowerCase()
  const product = query.product?.toLowerCase()
  const afid = query.afid
  // const campaign = query.utm_campaign?.toLowerCase()

  if (query.language) language = query.language

  const bh = brand.id === 'bh'
  const m10 = brand.id === 'm10'
  const ayb = brand.id === 'ayb'
  const med10 = brand.id === 'med10'
  const boomer = brand.id === 'boomer'

  const notProd = import.meta.env.MODE !== 'production'

  switch (true) {
    case notProd:
      return {
        placementId: 'Kcyq_Ynw0sN5cKLLw-pE1kpwUXyP3g',
        default: '18557651071',
        afid: '000000',
      }

    case bh && source === 'tbl':
      return {
        placementId: 'fdXBT-m7QIcWixdJJLtcejtU8fDi0A',
        default: '18334350573',
        afid: '811066',
        channel: 'contextual',
      }

    case bh && source === 'facebook' && product === 'aca':
      return {
        placementId: '5yPWl0ZJR7tCEFwEPdgci3zwLhRJhw',
        default: '18449372025',
        afid: '811040',
        channel: 'social',
      }

    case bh && source === 'facebook' && language === 'spanish':
      return {
        placementId: 'vaA5IpUf0FKvVFpjHesGe6qIQUl-sA',
        default: '18888062064',
        afid: '811247',
        channel: 'social',
      }

    case bh && source === 'facebook':
      return {
        placementId: 'syUSWk0fIpgPGUU38LH3M_BXhlmupg',
        default: '18337131570',
        afid: '810984',
        channel: 'social',
      }

    case bh && source === 'google' && language === 'spanish':
      return {
        placementId: 'UTf2NGR4KbVIwllq9kI6xh7ooZUgMA',
        default: '18885689485',
        afid: '811318',
        channel: 'display',
      }

    case bh && source === 'google' && medium === 'search':
      return {
        placementId: 'MbRbcqgCrAJt7dOnx5TaGi1l-arRIw',
        default: '18882945785',
        afid: '811038',
        channel: 'search',
      }

    case bh && source === 'youtube' && language === 'spanish':
      return {
        placementId: '8b854UpZ9zl6Cje6fNK-jzYTr83uCA',
        default: '18444160367',
        afid: '811323',
        channel: 'display',
      }

    case bh && source === 'youtube':
      return {
        placementId: 'u9lW1XVuClCkhD11xqKJePsVAC-rLQ',
        default: '18556946825',
        afid: '811297',
        channel: 'display',
      }

    case bh && source === 'google':
      return {
        placementId: 'l2hQu-r1Y7OE2C5HwPjRPsofNBoYuA',
        default: '18557151220',
        afid: '811192',
        channel: 'display',
      }

    case bh && source === 'sf':
      return {
        placementId: 'VeXUKzqHr4u_WF2uX_0jmIplU3z_Pg',
        default: '18557126185',
      }

    case bh && source === 'qst':
      return {
        placementId: 'G1O84yLyOwnusQwTij3xxa6fdMnaRg',
        default: '18886136252',
        afid: '811251',
        channel: 'contextual',
      }

    case bh && source === 'tiktok' && product === 'aca':
      return {
        placementId: 'wWj7sxRwLB1qxRXjdmg0HCHbHyUjxg',
        default: '18446361799',
        afid: '811265',
        channel: 'social',
      }

    case bh && source === 'tiktok':
      return {
        placementId: 'gkqOF21gm2CLZ0UNNaQdSmDqGZQQeg',
        default: '18559510581',
        afid: '811264',
        channel: 'social',
      }

    case bh && source === 'apl':
      return {
        placementId: 'uE1GAyTxhdzoSUYvsywFAgdlnyz1HQ',
        default: '18337172060',
        afid: '811165',
        channel: 'contextual',
      }

    case bh && source === 'apx' && medium === 't1':
      return {
        placementId: '4VqJZE58638sBV2s8vfbRsmEp1xAgQ',
        default: '18449761309',
        afid: '811055',
        channel: 'contextual',
      }

    case bh && source === 'apx' && medium === 't2':
      return {
        placementId: '37_Kx6aWXe1LKUY5x0SaRdpsB8QegQ',
        default: '18775655585',
        afid: '811056',
        channel: 'contextual',
      }

    case bh && source === 'apx':
      return {
        placementId: 'NgnnAgsTLWO0o6aTSqlXJAw-QHjesw',
        default: '18447051812',
        afid: '811056',
        channel: 'contextual',
      }

    case bh && source === 'maox':
      return {
        placementId: 'voFvGZ3s_5s1UkJDJQqw1PCD3Fq_Dw',
        default: '18669944962',
        afid: '811260',
        channel: 'contextual',
      }

    case bh && source === 'suco':
      return {
        placementId: 'SBryD3MQksZ-J_95Z90htV3iKAIIUQ',
        default: '18555906863',
        afid: '811258',
        channel: 'contextual',
      }

    case bh && source === 'qmp':
      return {
        placementId: 'G1O84yLyOwnusQwTij3xxa6fdMnaRg',
        default: '18886136252',
        afid: '811251',
        channel: 'contextual',
      }

    case bh && source === 'sa':
      return {
        placementId: 'u1FnKfuUIfYXGCdUD5F-t_PMDyS7CQ',
        default: '18889957304',
        afid: '811274',
        channel: 'display',
      }

    case bh && source === 'snapchat':
      return {
        placementId: 'ORk33iTIgjcAmBXtbn4YoKppqdobPA',
        default: '18444990707',
        afid: '811290',
        channel: 'social',
      }

    case bh && source === 'lgl':
      return {
        placementId: '__jT_8B3Jd3ngw3fmIUYP-ZtRSAqmQ',
        default: '18557345426',
        afid: '811171',
        channel: 'contextual',
      }

    case bh && afid === '811331':
      return {
        placementId: 'PEvwgP0JsSClnrKl_ajBwRJx4jzQ3A',
        default: '18336851702',
        afid: '811331',
        channel: 'search',
      }

    case bh && medium === 'aff':
      return {
        placementId: '__jT_8B3Jd3ngw3fmIUYP-ZtRSAqmQ',
        default: '18665615698',
        afid: '811241',
        channel: 'contextual',
      }

    case bh && language === 'spanish':
      return {
        placementId: '2hP--43s624CAqOixziMBTyuk0na6w',
        default: '18554760736',
        afid: '811308',
        channel: 'contextual',
      }

    case bh:
      return {
        placementId: 'qJJD7vjIH43fBjnh2BIZNqL0hqB-Aw',
        default: '18336221595',
        afid: '811217',
        channel: 'direct',
      }

    case ayb && source === 'google':
      return {
        placementId: 'av9FTtNbrgnn0tVESLCRKYMhE8cqhg',
        default: '18885056293',
        afid: '811074',
        channel: 'display',
      }

    case ayb && source === 'qst':
      return {
        placementId: 'l67O5RnsJylnVtFQboHlXo5DkG-_UQ',
        default: '18556400557',
        afid: '811267',
        channel: 'contextual',
      }

    case ayb && source === 'apl':
      return {
        placementId: '0yAloQDlvq_qbnkretTRUATFnXmKIw',
        default: '18556320431',
        afid: '811270',
        channel: 'contextual',
      }

    case ayb && source === 'eipx':
      return {
        placementId: 'aW4IKSarkYcUGlDNR2gec2OS_gHjKA',
        default: '18775617248',
        afid: '811271',
        channel: 'contextual',
      }

    case ayb && source === 'apx':
      return {
        placementId: 'As6N7-ZfaiTO0ytObaXaUTIWOZCymA',
        default: '18553305304',
        afid: '811268',
        channel: 'contextual',
      }

    case ayb && source === 'suco':
      return {
        placementId: 'QmLlGGo6bsK0rmjpOaA4aWwqFK77QA',
        default: '18556474391',
        afid: '811272',
        channel: 'contextual',
      }

    case ayb && source === 'maox':
      return {
        placementId: 'W4FcsNBxGjT8qskqAERwi-vE5BzFeA',
        default: '18778396544',
        afid: '811269',
        channel: 'contextual',
      }

    case ayb && source === 'snapchat':
      return {
        placementId: 'O9z8s-2LvwSy_pXBgnVQew1R5UPQbnQ',
        default: '18888949608',
        afid: '811315',
        channel: 'social',
      }

    case ayb && source === 'facebook':
      return {
        placementId: '5yPWl0ZJR7tCEFwEPdgci3zwLhRJhw',
        default: '18449372025',
        afid: '811040',
        channel: 'social',
      }

    case ayb && medium === 'aff':
      return {
        placementId: 'asNCSXuD3butGjTdB6rPsIo4C5z6ZQ',
        default: '18559423622',
        afid: '811253',
        channel: 'contextual',
      }

    case ayb:
      return {
        placementId: 'xsrTZdV32KSmi_iL3uOBL0X2KKMsIQ',
        default: '18449924248',
        afid: '811070',
        channel: 'direct',
      }

    case m10 && source === 'apl':
      return {
        placementId: 'uE1GAyTxhdzoSUYvsywFAgdlnyz1HQ',
        default: '18337172060',
        afid: '811165',
        channel: 'contextual',
      }

    case m10 && source === 'eipx':
      return {
        placementId: '7tNdiU83ILiv-uKbXMljEpyIU3INgQ',
        default: '18339781905',
        afid: '811166',
        channel: 'contextual',
      }

    case m10 && source === 'lgl':
      return {
        placementId: 'kRNgUtoLon1gsiGlfwxqzBXQZ9hhnA',
        default: '18776403822',
        afid: '811069',
        channel: 'contextual',
      }

    case m10 && source === 'tbl':
      return {
        placementId: 'fdXBT-m7QIcWixdJJLtcejtU8fDi0A',
        default: '18334350573',
        afid: '811066',
        channel: 'display',
      }

    case m10 && source === 'czl':
      return {
        placementId: 'd15wiDQfiZwZwf5R_7b9peSNrj_gcA',
        default: '18559154271',
        afid: '811169',
      }

    case m10 && source === 'obn':
      return {
        placementId: 'Rsd4V04Me7so-AyOASiDxRUoMkvexg',
        default: '18336450534',
        afid: '811170',
        channel: 'contextual',
      }

    case m10 && source === 'md':
      return {
        placementId: '__jT_8B3Jd3ngw3fmIUYP-ZtRSAqmQ',
        default: '18333497540',
        afid: '811250',
        channel: 'contextual',
      }

    case m10 && source === 'apx' && medium === 't1':
      return {
        placementId: '4VqJZE58638sBV2s8vfbRsmEp1xAgQ',
        default: '18449761309',
        afid: '811055',
        channel: 'contextual',
      }

    case m10 && source === 'apx' && medium === 't2':
      return {
        placementId: '37_Kx6aWXe1LKUY5x0SaRdpsB8QegQ',
        default: '18775655585',
        afid: '811056',
        channel: 'contextual',
      }

    case m10 && source === 'apx':
      return {
        placementId: 'NgnnAgsTLWO0o6aTSqlXJAw-QHjesw',
        default: '18447051812',
        afid: '811056',
        channel: 'contextual',
      }

    case m10 && source === 'maox':
      return {
        placementId: 'voFvGZ3s_5s1UkJDJQqw1PCD3Fq_Dw',
        default: '18669944962',
        afid: '811260',
        channel: 'contextual',
      }

    case m10 && source === 'sf':
      return {
        placementId: 'VeXUKzqHr4u_WF2uX_0jmIplU3z_Pg',
        default: '18557126185',
      }

    case m10 && source === 'youtube':
      return {
        placementId: 'Wy03EiLuxQBPf0Tg-9psFOqnm1E-PQ',
        default: '18446523014',
        afid: '811297',
        channel: 'display',
      }

    case m10 && source === 'google' && medium === 'search':
      return {
        placementId: 'cdafS5e6wGIiWV-WfYI8HdNNPQYWeQ',
        default: '18339183079',
        afid: '811324',
        channel: 'search',
      }

    case m10 && source === 'google' && language === 'spanish':
      return {
        placementId: 'UTf2NGR4KbVIwllq9kI6xh7ooZUgMA',
        default: '18885689485',
        afid: '811246',
        channel: 'display',
      }

    case m10 && source === 'google':
      return {
        placementId: 'pOabRc17MPjlFfu3hoYVDorx6-TIaA',
        default: '18444090332',
        afid: '811025',
        channel: 'display',
      }

    case m10 && source === 'facebook':
      return {
        placementId: 'GdH2gFR8XJzdcs1HpyXL4WfqVgzwkw',
        default: '18773895135',
        afid: '810989',
        channel: 'social',
      }

    case m10 && medium === 'aff':
      return {
        placementId: '__jT_8B3Jd3ngw3fmIUYP-ZtRSAqmQ',
        default: '18665615698',
        afid: '811241',
        channel: 'contextual',
      }

    case m10 && language === 'spanish':
      return {
        placementId: '2hP--43s624CAqOixziMBTyuk0na6w',
        default: '18554760736',
        afid: '811317',
        channel: 'contextual',
      }

    case m10:
      return {
        placementId: 'JHqkls-LrwvXE8Hd-ZMf7ijddY7wVw',
        default: '18335732845',
        afid: '810983',
        channel: 'direct',
      }

    case boomer && source === 'facebook':
      return {
        placementId: 'f_UbB19O1WFfBGpaQFEgxs5hAp0w2g',
        default: '18444901521',
        afid: '811019',
        channel: 'social',
      }

    case boomer:
      return {
        placementId: 'sm0Eh2fHarV8_KkPoTAysNfkNr11NA',
        default: '18666967616',
        afid: '811245',
        channel: 'direct',
      }

    case med10 && source === 'facebook':
      return {
        placementId: 'hPiCQFF77bBPizar31iJ0ujaAn6CzQ',
        default: '18777301943',
        afid: '811051',
        channel: 'social',
      }

    case med10 && source === 'apl':
      return {
        placementId: 'uE1GAyTxhdzoSUYvsywFAgdlnyz1HQ',
        default: '18337172060',
        afid: '811165',
        channel: 'contextual',
      }

    case med10 && source === 'apx' && medium === 't1':
      return {
        placementId: '4VqJZE58638sBV2s8vfbRsmEp1xAgQ',
        default: '18449761309',
        afid: '811055',
        channel: 'contextual',
      }

    case med10 && source === 'apx' && medium === 't2':
      return {
        placementId: '37_Kx6aWXe1LKUY5x0SaRdpsB8QegQ',
        default: '18775655585',
        afid: '811056',
        channel: 'contextual',
      }

    case med10 && source === 'apx':
      return {
        placementId: 'NgnnAgsTLWO0o6aTSqlXJAw-QHjesw',
        default: '18447051812',
        afid: '811056',
        channel: 'contextual',
      }

    case med10 && source === 'maox':
      return {
        placementId: 'voFvGZ3s_5s1UkJDJQqw1PCD3Fq_Dw',
        default: '18669944962',
        afid: '811260',
        channel: 'contextual',
      }

    case med10 && source === 'suco':
      return {
        placementId: 'SBryD3MQksZ-J_95Z90htV3iKAIIUQ',
        default: '18555906863',
        afid: '811258',
        channel: 'contextual',
      }

    case med10 && source === 'qmp':
      return {
        placementId: 'G1O84yLyOwnusQwTij3xxa6fdMnaRg',
        default: '18886136252',
        afid: '811251',
        channel: 'contextual',
      }

    case med10 && source === 'zto':
      return {
        placementId: 'RbHUaBcMy2D4VIICl9aWxrUB9l9uOg',
        default: '18558443573',
        afid: '811288',
        channel: 'contextual',
      }

    case med10:
      return {
        placementId: 'ubc3ytI-mt_08U4B_xUEdZctOtNwXQ',
        default: '18666968144',
        afid: '811244',
        channel: 'direct',
      }

    default:
      return brand.phone
  }
}

export const phoneConfig = ref<PhoneConfig>()

export const placementId = computed(() => {
  return typeof phoneConfig.value === 'object' &&
    'placementId' in phoneConfig.value
    ? phoneConfig.value.placementId
    : null
})

const campaignId = computed(() => {
  return typeof phoneConfig.value === 'object' &&
    'campaignId' in phoneConfig.value
    ? phoneConfig.value.campaignId
    : null
})

const dynamicPhoneConfig = ref<PhoneConfig | null>()

export const usePhone = (value: PhoneConfig) => {
  dynamicPhoneConfig.value = value

  onBeforeUnmount(() => {
    dynamicPhoneConfig.value = null
  })
}

const ringPoolNumber = ref<RingPoolNumber>({})

const onGetRingPoolNumberFail = _.once((payload: any, error?: any) => {
  Sentry.captureMessage('Failed to get MA ring pool number', {
    level: 'error',
    extra: {
      ...payload,
      error,
    },
  })
})

const getRingPoolNumber = (payload: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    until(maLoaded)
      .toBe(true)
      .then(() => {
        window.MediaAlphaExchange = payload
        window.MediaAlphaExchange__loadIVRPool((phone_num: any, error: any) => {
          if (error) {
            onGetRingPoolNumberFail(payload, error)
            reject()
            return
          }
          if (phone_num) {
            resolve({
              phone_num,
              expiration_time: DateTime.now()
                .plus({ seconds: payload.phone_num_lease_time })
                .toISO(),
            })
          } else {
            onGetRingPoolNumberFail(payload)
            reject()
          }
        })
      })
  })
}

const ringPoolUserData = ref<Record<string, any>>({})

export const setRingPoolUserData = (data: Record<string, any>) => {
  ringPoolUserData.value = {
    ...ringPoolUserData.value,
    ...data,
  }
  ringPoolNumber.value = _.mapValues(ringPoolNumber.value, (value) => {
    if (!value) return value
    return {
      ...value,
      expirationDate: Date.now() + 10 * 1000,
    }
  })
}

whenever(
  xsmid,
  () => {
    setRingPoolUserData({})
  },
  { flush: 'sync' }
)

watch(placementId, async (pid) => {
  if (pid) {
    if (!ringPoolNumber.value[pid]) {
      ringPoolNumber.value = {
        ...ringPoolNumber.value,
        [pid]: {
          value: null,
          expirationDate: null,
        },
      }

      const afid = (phoneConfig.value as { afid?: string }).afid
      const channel = (phoneConfig.value as { channel?: string }).channel

      while (true) {
        if (
          !ringPoolNumber.value[pid]!.value ||
          Date.now() > ringPoolNumber.value[pid]!.expirationDate! - 20 * 1000
        ) {
          try {
            await until(() => ip.lookup).toBeTruthy()

            const res = await getRingPoolNumber({
              data:
                query.zip && /^[0-9]{5}$/.test(query.zip)
                  ? {
                      zip: query.zip,
                      ...ringPoolUserData.value,
                    }
                  : ringPoolUserData.value,
              ip: ip.lookup!.value,
              local_hour: new Date().getHours(),
              phone_num_lease_time: 300,
              placement_id: pid,
              ua: navigator.userAgent,
              url: location.href,
              version: 17,
              sub_1: getSessionEnhanced().query.afid || afid,
              sub_2: segment.anonymousId,
              sub_3: getSession().id,
              source: [
                getSessionEnhanced().query.afid || afid || 'null',
                query.utm_campaign || 'null',
                query.adgroupid || 'null',
                query.publisher_id || 'null',
                query.publisher || 'null',
                channel || 'null',
                xsmid.value || 'null',
              ],
            })

            ringPoolNumber.value = {
              ...ringPoolNumber.value,
              [pid]: {
                value: res.phone_num.slice(1),
                expirationDate: new Date(res.expiration_time).getTime(),
              },
            }
          } catch (err) {}
        }
        await new Promise((resolve) => setTimeout(resolve, 4000))
      }
    }
  }
})

watch(campaignId, (cid) => {
  if (cid) {
    useScriptTag(`https://b-js.ringba.com/${cid}`)
  }
})

const timestamp = useTimestamp()

export const phone: Phone = reactive({
  value: computed(() => {
    if (typeof phoneConfig.value === 'object') {
      if ('placementId' in phoneConfig.value) {
        const number = ringPoolNumber.value[phoneConfig.value.placementId]
        return number?.value && number.expirationDate! > timestamp.value
          ? number.value
          : phoneConfig.value.default
      }
      return phoneConfig.value.replacementNumber
    }
    return phoneConfig.value || brand.phone
  }),
  formatted: computed(() => {
    const v = phone.value
    return `${v.slice(0, 1)}-${v.slice(1, 4)}-${v.slice(4, 7)}-${v.slice(7)}`
  }),
  uri: computed(() => {
    return `tel:+${phone.value}`
  }),
})

onInit(({ isClient, router }) => {
  if (isClient) {
    router.isReady().then(() => {
      const globalPhoneConfig = globalPhone(
        (router.currentRoute.value.meta.language as string | undefined) ||
          'english'
      )

      const routePhoneConfig = computed(() => {
        return router.currentRoute.value.meta.phone as PhoneConfig | undefined
      })

      watchSyncEffect(() => {
        phoneConfig.value =
          dynamicPhoneConfig.value ||
          routePhoneConfig.value ||
          globalPhoneConfig
      })
    })
  }
})
