// @unocss-include

const logos = _.mapKeys(
  import.meta.glob('../assets/logo-*.png', { eager: true, as: 'url' }),
  (value, key) => key.slice(15, -4)
)

const brands = {
  m10: {
    id: 'm10',
    name: 'Medicare10',
    url: 'Medicare10.com',
    phone: '18335732845',
    favicon: '/favicon.ico',
    measurementId: '6SZWQRZN9W',
    logo: {
      url: logos.m10,
      class: 'h-12 md:h-14 w-auto',
      width: 412,
      height: 88,
    },
    logoSmall: {
      url: logos.small,
      class: 'h-8 md:h-10',
    },
  },
  ayb: {
    id: 'ayb',
    name: 'Activate Your Benefits',
    url: 'ActivateYourBenefits.org',
    phone: '18663291920',
    favicon: '/favicon.ico',
    measurementId: null,
    logo: {
      url: logos.ayb,
      class: 'h-12 md:h-14 w-auto',
    },
    logoSmall: {
      url: logos.small,
      class: 'h-8 md:h-10',
    },
  },
  bh: {
    id: 'bh',
    name: 'Benefit Helpline',
    url: 'Benefit-Helpline.com',
    phone: '18557571250',
    favicon: '/favicon-bh.ico',
    measurementId: 'F3SR0GE0JN',
    logo: {
      url: logos.bh,
      class: 'h-12 md:h-14 w-auto',
    },
    logoSmall: {
      url: logos['small-bh'],
      class: 'h-8 md:h-10',
    },
  },
  med10: {
    id: 'med10',
    name: 'Med10',
    url: 'Med10.org',
    phone: '18335732845',
    favicon: '/favicon.ico',
    measurementId: 'FPLT66V8WB',
    logo: {
      url: logos.med10,
      class: 'h-14 w-auto',
    },
    logoSmall: {
      url: logos.small,
      class: 'h-8 md:h-10',
    },
  },
  boomer: {
    id: 'boomer',
    name: 'This Boomer',
    url: 'ThisBoomer.com',
    phone: '18444151627',
    favicon: '/favicon-boomer.ico',
    measurementId: null,
    logo: {
      url: logos.boomer,
      class: 'h-12 md:h-14 w-auto',
    },
    logoSmall: {
      url: logos['small-boomer'],
      class: 'h-8 md:h-10',
    },
  },
  bridge: {
    id: 'bridge',
    name: 'General Medicare',
    url: 'General-Medicare.com',
    phone: '18335732845',
    favicon: '/favicon-general.ico',
    measurementId: 'F3SR0GE0JN',
    logo: {
      url: logos.bridge,
      class: 'h-12 md:h-14 w-auto',
      width: 412,
      height: 88,
    },
    logoSmall: {
      url: logos['small-bridge'],
      class: 'h-8 md:h-10',
    },
  },
  ac: {
    id: 'ac',
    name: 'AllowanceCards.com',
    url: 'AllowanceCards.com',
    phone: '18335732845',
    favicon: '/favicon-ac.ico',
    measurementId: null,
    logo: {
      url: logos.ac,
      class: 'h-12 md:h-14 w-auto',
      width: 479,
      height: 59,
    },
    logoSmall: {
      url: logos.small,
      class: 'h-8 md:h-10',
    },
  },
}

export const brand = brands[__BRAND__]
