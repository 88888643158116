<script setup lang="ts">
import {
  ChatHeadline,
  Content,
  SelectedOptionEmit,
  SelectedAnswersEmit,
  Message,
} from '~/modules/chat-ad'

const props = defineProps<{
  content: Content
  headline?: ChatHeadline
  getMessageBlockById: (stepId: string) => Array<Message> | null
}>()

const emit = defineEmits<{
  (
    e: 'onSelectAnswer',
    nextStepId: string,
    selecetedAnswer: SelectedAnswersEmit,
    callback: (response: any) => void
  ): Promise<Message[]>
  (e: 'onPostCall', ms: number): void
}>()

const reminderIsVisible = ref(false)
const renderedMessages = ref<Message[]>([])
const nextMessages = ref<Message[]>([])
const currentStepId = ref<string>(props.content.configuration.initialBlockId)

const updateAvatarVisibility = () => {
  if (renderedMessages.value.length > 0) {
    for (let i = 0; i < renderedMessages.value.length - 1; i++) {
      renderedMessages.value[i].shouldShowAvatar =
        renderedMessages.value[i].from !== renderedMessages.value[i + 1].from ||
        !renderedMessages.value[i + 1]
    }

    renderedMessages.value[renderedMessages.value.length - 1].shouldShowAvatar =
      true
  }
}

const currentIndex = ref(0)

const startAnimation = async () => {
  if (nextMessages.value.length === 0) {
    return
  }
  const next = nextMessages.value?.shift()
  if (next) {
    if (
      nextMessages.value.length === 0 &&
      !reminderIsVisible.value &&
      next.shouldShowReminder
    ) {
      startReminderTimer()
    }
    renderedMessages.value.push({
      ...next,
      isWaiting: next.type !== 'zip',
    })
    updateAvatarVisibility()
    setTimeout(async () => {
      if (renderedMessages.value.length > 0) {
        renderedMessages.value[currentIndex.value].isWaiting = false
        currentIndex.value++
      }
      await startAnimation()
    }, 1300)
  }
}

const handleVisibilityChange = () => {
  if (document.visibilityState === 'visible') {
    isPageFocused = true
    if (
      nextMessages.value.length === 0 &&
      !reminderIsVisible.value &&
      renderedMessages.value.at(renderedMessages.value.length - 1)
        ?.shouldShowReminder
    ) {
      startReminderTimer()
    }
  } else {
    isPageFocused = false
    resetTimer()
  }
}

const handleFocus = () => {
  isPageFocused = true
  startReminderTimer()
}

const handleBlur = () => {
  isPageFocused = false
  resetTimer()
}

onMounted(() => {
  addNewMessages({
    stepId: props.content.configuration.initialBlockId ?? 'init',
    shouldBlockQuestion: true,
  })
  document.addEventListener('visibilitychange', handleVisibilityChange)
  window.addEventListener('focus', handleFocus)
  window.addEventListener('blur', handleBlur)
})

onUnmounted(() => {
  document.removeEventListener('visibilitychange', handleVisibilityChange)
  window.removeEventListener('focus', handleFocus)
  window.removeEventListener('blur', handleBlur)
})

const scrollToBottom = () => {
  nextTick(() => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    })
  })
}

watch(
  renderedMessages,
  () => {
    nextTick(() => {
      const chatWrapper = document.querySelector('.body')
      if (chatWrapper && chatWrapper.scrollHeight > window.innerHeight) {
        scrollToBottom()
      }
    })
  },
  { deep: true }
)

function onSelectAnswer({
  nextStepId,
  eventProperty,
  eventValue,
}: {
  nextStepId: string
  eventProperty: string
  eventValue: any
}): Promise<Message[]> {
  return new Promise((resolve) => {
    emit('onSelectAnswer', nextStepId, { [eventProperty]: eventValue }, resolve)
  })
}

let timer: ReturnType<typeof setTimeout> | null = null
let isPageFocused = true

const startReminderTimer = () => {
  if (!isPageFocused) {
    return
  }

  if (timer) {
    clearTimeout(timer)
  }

  if (!reminderIsVisible.value) {
    timer = setTimeout(
      triggerEvent,
      props.content.configuration.reminder?.reminderTimeout ?? 10000
    )
  }
}

const addNewMessages = async ({
  stepId,
  shouldBlockQuestion = true,
}: {
  stepId: string
  shouldBlockQuestion?: boolean
}) => {
  const newMessages = props.getMessageBlockById(stepId)?.filter(Boolean) ?? []
  renderMessages({
    messages: newMessages,
    shouldBlockQuestion,
  })
}

const renderMessages = async ({
  messages,
  shouldBlockQuestion,
}: {
  messages: Message[]
  shouldBlockQuestion?: boolean
}) => {
  if (messages && messages.length) {
    nextMessages.value.push(...messages)
    startAnimation()

    if (shouldBlockQuestion) {
      renderedMessages.value = renderedMessages.value.map((element, index) => {
        if (index < renderedMessages.value.length - 1) {
          element.clicked = true
        }
        return element
      })
    }
  }
}

const triggerEvent = () => {
  addNewMessages({
    stepId: props.content.configuration.reminder?.reminderBlockId ?? 'reminder',
    shouldBlockQuestion: true,
  })
  reminderIsVisible.value = true
}

const resetTimer = () => {
  if (timer) {
    clearTimeout(timer)
  }
}

const onSelectOption = async (selectedOption: SelectedOptionEmit) => {
  resetTimer()
  const { nextStepId, eventProperty, eventValue } = selectedOption
  const hasNextStep = !!props.content.messageBlocks?.[nextStepId]

  if (hasNextStep) {
    if (
      eventProperty &&
      typeof eventValue !== 'undefined' &&
      eventValue !== null
    ) {
      const newMessages = await onSelectAnswer({
        nextStepId,
        eventProperty,
        eventValue,
      })
      renderMessages({ messages: newMessages, shouldBlockQuestion: true })
      analytics.track('formStepSubmitted', {
        formVersion: props.content.configuration.id,
        stepName: currentStepId.value,
        data: {
          [eventProperty]: eventValue,
        },
      })

      currentStepId.value = nextStepId
      return
    }
    addNewMessages({ stepId: nextStepId, shouldBlockQuestion: true })
  }
}

const onPostCall = () => {
  if (props.content.configuration.postCallId) {
    addNewMessages({
      stepId: props.content.configuration.postCallId,
    })
  }
}
</script>
<template>
  <div
    class="header p-2 gap-4 flex items-end border-b border-gray-200 shadow-md"
    v-if="headline"
  >
    <div
      class="max-w-sm mx-auto relative flex gap-4 items-center dark:text-white"
    >
      <div class="flex gap-2 rounded-full relative">
        <img
          :src="headline.image"
          class="w-8 h-8 rounded-full self-end"
          alt="Header image "
        />
        <div
          class="online rounded-full h-3 w-3 bg-emerald-500 bottom-0 right-0 absolute border border-gray-300"
        ></div>
      </div>
      <span>
        {{ headline.advisorName }}
        <span class="text-xs text-gray-500 dark:text-gray-200">
          {{ brand.name }}
        </span>
      </span>
    </div>
  </div>
  <div
    :class="[
      'body max-w-sm p-4 mx-auto flex flex-col gap-4',
      'dark:text-white text-black',
    ]"
  >
    <div
      class="benefit-wrapper flex items-center flex-col gap-4 mb-4"
      v-if="headline"
    >
      <img
        :src="headline.image"
        class="rounded-full h-32 w-32 object-cover"
        alt="Headline image "
      />
      <div class="flex flex-col gap-2 items-center">
        <h2 class="font-semibold text-lg">{{ headline.title }}</h2>
        <span class="text-base">{{ headline.subtitle }}</span>
      </div>
    </div>

    <div class="gap-2" ref="chatWrapper">
      <transition-group
        name="list"
        tag="div"
        class="font-sans flex flex-col gap-1"
      >
        <ChatBubble
          v-for="(item, index) in renderedMessages"
          :key="index"
          :render-message-waiting="item.isWaiting ?? false"
          :message="item"
          @select-option="onSelectOption"
          :connect-top="
            index > 0 &&
            renderedMessages[index].from === renderedMessages[index - 1].from
          "
          :connect-bottom="
            index < renderedMessages.length - 1 &&
            renderedMessages[index].from === renderedMessages[index + 1].from
          "
          :enable-post-call="!!content.configuration.postCallId"
          @on-post-call="onPostCall"
        />
      </transition-group>
    </div>
  </div>
</template>
