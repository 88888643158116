<script setup lang="ts">
import { PlanRowView } from '~/modules/plan'

useHead({
  title: `Shop 4 DSNP OTC - ${brand.name}`,
})

const minOtcLtd = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    1500
  )
})

// enterExperiment('shop4DsnpOTC2024Headline')
// enterExperiment('shop4DsnpOTC2024Description')

const state = computed(() => ip.lookup?.stateCode ?? '')

const plans = ref<PlanRowView[]>(Array(30))

onMounted(async () => {
  if (
    exp.value?.shop4DsnpOTC2024Description?.countDsnpPlans ||
    exp.value?.shop4DsnpOTC2024Description?.countPlansInState
  ) {
    try {
      await until(() => ip.lookup).toBeTruthy()

      const zipCode = ip.lookup?.postalCode
      if (zipCode) {
        plans.value = await getPlans({
          zipCode: zipCode,
          amount: 1000,
          starting_from: 0,
        })
      }
    } catch (e) {
      console.error('Could not load plans', { e })
    }
  }
})

const headline = computed(() => {
  const headline =
    getCurentValidExperiment(exp.value?.shop4DsnpOTC2024Headline) ?? 'compare'
  switch (headline) {
    case 'medicareAdvantageDsnp':
      return `2024 Medicare Advantage D-SNP Plans with Flexible Spending Allowance`
    case 'medicareVariableMinAmount':
      return `Medicare Advantage D-SNP Plans with ${USDollarFormatter(0).format(
        minOtcLtd.value
      )}/yr or more Grocery Allowance`

    case 'variableMinAmountGroceryAllowance':
      return `${USDollarFormatter(0).format(
        minOtcLtd.value
      )}/year or more Grocery Allowance with these Medicare Advantage D-SNP Plans`
    case 'affordableMedicare':
      return `Affordable Medicare Advantage D-SNP Plans with Food Allowance Benefits`
    default:
      return `Compare 2024 Medicare Advantage Special Needs Plans`
  }
})

const description = computed(() => {
  const description =
    getCurentValidExperiment(exp.value?.shop4DsnpOTC2024Description) ??
    'shopMedicareCommon'
  switch (description) {
    case 'shopMedicareApproved':
      return `Shop Medicare Advantage plans that include a flexible spending allowance for approved items you can use at many major retailers`
    case 'variableMinAmount':
      return `${USDollarFormatter(0).format(
        minOtcLtd.value
      )}/year or More in OTC allowance benefits`
    case 'flexibleAlloance':
      return `Flexible Allowance Benefit: Get ${USDollarFormatter(0).format(
        minOtcLtd.value
      )}/year or more on a pre-paid card to use on approved items`
    case 'prePaidCard':
      return `Pre-paid card included with some Medicare Advantage plans for approved items.`
    case 'lookingForFlexible':
      return `Looking for a Flexible Spending Allowance OTC Benefit? We can help.`
    case 'variableMinAmountFlexible':
      return `${USDollarFormatter(0).format(
        minOtcLtd.value
      )} or more in flexible spending benefits for approved items`
    case 'variableMinAmountFood':
      return `${USDollarFormatter(0).format(
        minOtcLtd.value
      )} or More in Food Allowance`
    case 'youMayBeElegible':
      return `You May Be Eligible for ${USDollarFormatter(0).format(
        minOtcLtd.value
      )} or More in Grocery Allowance`
    case 'countPlansInState':
      return `${plans.value.length} Plans in ${
        state.value
      } include spending allowances of ${USDollarFormatter(0).format(
        minOtcLtd.value
      )}/per year or more for approved items`
    case 'countDsnpPlans':
      return `${plans.value.length} D-SNP Plans in ${
        state.value
      } include spending allowances of ${USDollarFormatter(0).format(
        minOtcLtd.value
      )}/per year or more for approved items at many major retailers`
    case 'somePlansProvide':
      return `Some plans provide a pre-paid card to use on approved items online and at many major retailers`
    default:
      return `Shop Medicare Advantage plans that include a flexible spending allowance for common OTC items.`
  }
})
</script>

<template>
  <Layout header="blue" footer="giveback" class="container">
    <template #banner v-if="!isMobile">
      <Banner> New to Medicare? On Medicaid? Moving? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <h2 class="space-y-2 text-lg text-red text-center font-bold">
        2024 {{ state }} Medicare Advantage Plans with at least
        {{ USDollarFormatter(0).format(minOtcLtd) }} Grocery Allowance
      </h2>

      <img
        src="../../assets/shop-4-dsnp-otc-2024medicareab-allowance-card.jpg"
        width="1514"
        height="756"
        class="w-[90%] sm:w-[400px] mx-auto"
        alt="Credit card illustration"
      />

      <div class="text-xl text-blue text-center font-semibold">
        Compare plans from carriers like <br />
        <span> Humana, Aetna, and UnitedHealthcare® </span>
      </div>

      <div class="border-b border-gray-500 border-dashed"></div>

      <div class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white">
        <Form
          id="shop4DsnpOtc2024"
          class="!max-w-3xl p-4"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'medicareab' },
            { id: 'medicaid' },
            { id: 'zipcode', if: !query.zipCode },
            { id: 'call', completed: true },
          ]"
          product="medicare"
        />
      </div>
      <div class="space-y-6">
        <div class="text-2xl text-center font-semibold">
          Compare 2024 MA Medicare Advantage Special Needs Plans
        </div>

        <div class="text-lg text-center text-gray-500">
          <ul class="list-disc pl-4">
            <li>
              Affordable Medicare Advantage D-SNP Plans with Food Allowance
              Benefits.
            </li>
            <li>
              Some plans provide a pre-paid card benefit to use on approved
              items online and at many major retailers."
            </li>
          </ul>
        </div>
        <div class="text-2xl text-center font-semibold">
          Tap Below to Speak with Licensed Agent Now
        </div>
        <div class="text-center text-gray-500">
          <ClickToCall v-slot="{ handleClick }">
            <Button :to="phone.uri" @click="handleClick">
              <Icon
                i-heroicons-solid:phone
                class="w-8 h-8 animate-shake mr-4"
              />
              <span>
                {{ phone.formatted }} <br />
                TTY 711 24/7
              </span>
            </Button>
          </ClickToCall>
        </div>
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "m10"],
    "benefits": ["food", "groceries", "otc"],
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0224144_M",
      "m10": "MULTI-PLAN_HLWEB0224150_M"
    },
    "xsmid": {
      "bh": "MULTI-PLAN_HLWEB0224144_M",
      "m10": "MULTI-PLAN_HLWEB0224150_M"
    }
  }
}
</route>
