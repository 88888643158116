<script setup lang="ts">
const value = useFieldValue('primaryphone')
const isValid = useIsFieldValid('primaryphone')
const validate = useValidateField('primaryphone')

onMounted(() => {
  validate()
})
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-2xl sm:text-3xl text-red text-center">
    Congratulations!
  </div>

  <div class="text-3xl sm:text-4xl text-center">
    Finish your application with a licensed insurance agent.
  </div>

  <template v-if="query.phone">
    <Field
      type="phone"
      name="primaryphone"
      label="Phone number"
      rules="required"
      :initialValue="query.phone"
    />

    <div class="text-sm sm:text-base text-gray-500 italic text-center">
      By clicking the button below, you agree to the terms below.
    </div>

    <ClickToCall
      :disabled="!isValid"
      :props="{
        ctcLocation: 'last-step',
        smsConsent: value,
      }"
      tty
    />

    <div class="text-gray-500 text-sm sm:text-base text-justify">
      This is a solicitation for insurance and related services and products
      including Medicare Advantage, Medicare Supplement Insurance, and Medicare
      Prescription Drug Plans and healthcare provider services. There is no
      obligation to enroll. Your inquiry may generate marketing and other
      communications by a licensed insurance agency or other insurance company
      or on behalf of a healthcare provider. Submission of your information does
      NOT affect your current enrollment, nor will it enroll you in a Medicare
      Advantage, Medicare Supplement Insurance plan, or other Medicare plan.
      <br />
      <br />
      By clicking the button on this page, you provide expressed consent to
      receive marketing and other communications from EasyHealth, its
      subsidiaries, and its partner
      <Link to="/carriers" target="_blank">companies</Link> via email, live
      telephone, automatic telephone dialing system, artificial or pre-recorded
      voice message, or SMS/MMS text message at the telephone number provided
      even if the number is on a federal, state, or company Do Not Call list.
      Your consent to receive communications is not required as a condition of
      purchasing any goods or services. Your telephone company may impose
      additional charges for text messages, and you may revoke your consent at
      any time through any reasonable manner including by contacting
      info@joineasyhealth.com.
      <br />
      <br />
      Please see our <Link to="/tos"> Terms and Conditions </Link> and
      <Link to="/privacy"> Privacy Policy </Link> for additional terms.
    </div>
  </template>
  <template v-else>
    <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />
  </template>
</template>
