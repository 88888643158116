<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'

const notProd = import.meta.env.MODE !== 'production'

const loading = ref(true)
const source = ref(0)

const isPaidUser = ref(true)
const router = useRouter()

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if (!isPaidUser.value && import.meta.env.MODE === 'production') {
    router.push('/go-4-benefits')
    return
  }
})

const destination = computed(() => {
  if (!isMobile && import.meta.env.MODE === 'production') {
    return '/ads'
  }
  let value = '/additional-benefits-grocery-2024/call-now'

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    const domain = 'https://benefit-helpline.com'
    const params = new URLSearchParams()

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)

    if (exp.value?.otcAdDestination) {
      const variant = exp.value.otcAdDestination.toBenefits
        ? 'toBenefits'
        : 'original'

      params.set('exp', `otc-ad-destination-2023-10-20@${variant}`)
    }

    const qs = params.toString() && `?${params.toString()}`

    value = domain + value + qs
  }

  return value
})

const stateConfig = computed(() => {
  const minOtcLtd =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  const minOtc =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtc ?? 0

  return {
    minOtcLtd: minOtcLtd,
    minOtc: minOtc,
  }
})

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })
  if (notProd) {
    source.value = 0
    setTimeout(() => (loading.value = false), 50)
    source.value = stateConfig.value?.minOtc
  } else {
    source.value = stateConfig.value?.minOtc ?? 0
    setTimeout(() => (loading.value = false), 500)
    source.value = stateConfig.value?.minOtcLtd
  }
})

const output = useTransition(source, {
  duration: 2500,
  transition: TransitionPresets.easeInOutQuart,
})

analytics.page({
  name: 'OTC Bridge 4',
  category: 'LP',
  product: 'medicare',
})

const card = ref(null)

const { height } = useElementSize(card)

const name = computed(() => {
  const firstname = query.firstname?.trim() || ''
  const lastname = query.lastname?.trim() || ''
  const fullName = `${firstname} ${lastname}`.trim()
  return fullName.length > 15 && firstname && lastname
    ? `${firstname.charAt(0)} ${lastname}`
    : fullName
})

const showBackButtonModal = ref(false)

onMounted(() => {
  const forward = router.options.history.state.forward
  if (typeof forward === 'string' && forward.includes('grocery')) {
    showBackButtonModal.value = true
  }
})
</script>

<template>
  <Layout
    :hide-header="true"
    footer="2024"
    :class="
      [
        'bg-[#f7f7f7]',
        'flex flex-col min-h-[100vh] p-6 gap-6 md:gap-12 font-dm items-center relative',
      ].join(' ')
    "
  >
    <template v-if="notProd" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <span
      class="absolute text-xs top-2 right-2 border border-black p-1 rounded"
    >
      Ad
    </span>
    <span
      v-if="loading"
      class="inset-0 flex items-center justify-center font-dm"
    >
      <Spinner />
    </span>
    <div
      v-if="!loading"
      :class="[
        'container h-full flex-grow min-h-[calc(100vh-320px)] flex mx-auto',
        'gap-6 py-6 text-xl sm:text-3xl font-bold flex flex-col text-white md:justify-evenly md:items-center',
      ]"
    >
      <div class="md:flex-grow-0">
        <span class="text-4xl text-gray-800 font-bold font-dm">
          <template v-if="notProd">
            You may get
            <span class="text-red text-5xl"> ${{ output.toFixed(0) }} </span>
            or more annual OTC allowance
          </template>
          <template v-else>
            Get
            <span class="text-red text-5xl"> ${{ output.toFixed(0) }} </span>
            or more annual OTC allowance
          </template>
        </span>
      </div>
      <div>
        <div ref="card" class="aspect-[380/253] max-w-[380px] relative">
          <img
            src="../../assets/allowance-card-blank.png"
            class="w-full h-full"
          />
          <div
            class="absolute left-[8%] bottom-[11%] w-[62%] text-white uppercase font-sans font-normal"
            :style="{
              fontSize: `${height * 0.1}px`,
              lineHeight: `${height * 0.15}px`,
            }"
          >
            <template v-if="name">
              <Typewriter
                v-if="output === source"
                :text="name"
                class="truncate"
                cursor=""
                :speed="75"
              />
            </template>
            <div v-else class="truncate"> Emily Taylor </div>
          </div>
        </div>
      </div>
      <div v-if="notProd" class="text-gray-600 text-base font-sans text-center">
        With some Dual Eligible Special Needs Medicare Advantage Plans from
        carriers like Humana and UnitedHealthcare®
      </div>
      <div v-else class="text-gray-600 text-sm font-sans text-center">
        with some Medicare Advantage Plans
      </div>
      <div class="text-lg md:max-w-3xl md:w-full flex my-4 w-full">
        <Button
          :to="destination"
          :class="['bg-gradient-to-b from-[#00b2ff] to-[#006aff]', 'font-sans']"
          wide
          @click="
            analytics.track('learnMoreClicked', {
              source: $route.fullPath,
              destination,
            })
          "
        >
          <span> Learn More </span>
          <Icon i-heroicons-solid:arrow-right class="w-8 h-8 ml-4" />
        </Button>
      </div>
    </div>

    <Modal :open="showBackButtonModal" @close="showBackButtonModal = false">
      <ModalBackButton @close="showBackButtonModal = false" />
    </Modal>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "med10", "m10", "boomer", "bridge"],
    "robots": "noindex",
    "benefits": ["otc"]
  }
}
</route>
