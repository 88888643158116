import { DateTime } from 'luxon'

const ts = useTimestamp()

export const isOutsideWorkingHours = computed(() => {
  if (import.meta.env.SSR) return false

  const dt = DateTime.fromMillis(ts.value, { zone: 'America/New_York' })

  const hour = dt.hour
  const weekday = dt.weekday
  const month = dt.month
  const day = dt.day

  return hour < 9 || hour >= 18 || weekday >= 6
})

export const redirectToClicksFromFacebook = computed(() => {
  return isOutsideWorkingHours.value
})
